// PurchaseInfo.js
import React, { useState, useEffect, useRef } from 'react';
import { FaMapMarkerAlt, FaRegCreditCard, FaUserAlt } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { NativeUtilities } from '../../../utility/NativeUtilities';
import OrderQuote from '../cart/OrderQuote';
import { useMediaQuery } from 'react-responsive';
import { MdLocalPharmacy } from 'react-icons/md';
import Swal from 'sweetalert2';
import { setTempItems, changeSelectedQuote, setRefreshQuotes, addCheckedQuote, setHasShownNoQuotesWarning } from '../../../reducers/StoreSlice';
import styled, { keyframes } from 'styled-components';
import { IoMdRefresh } from 'react-icons/io';
import { QuoteService } from '../../../services/drsbee/data/QuoteService';
import { SignalingService } from '../../../services/drsbee/data/SignalingService';
import RefreshQuote from '../cart/RefreshQuote';
const nativeUtil = new NativeUtilities();
const signalingService = new SignalingService();
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;
const PurchaseInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addresses = useSelector((state) => state.store.addresses);
  const paymentMethods = useSelector((state) => state.store.paymentMethods);
  const quotes = useSelector((state) => state.store.quotes);
  const checkedQuotes = useSelector((state) => state.store.checkedQuotes);
  const selectedAddress = useSelector((state) => state.store.selectedAddress);
  const selectedPaymentMethod = useSelector((state) => state.store.selectedPaymentMethod);
  const selectedQuote = useSelector((state) => state.store.selectedQuote);
  const patient = useSelector((state) => state.store.loggedInPatient);
  const cacheItems = useSelector((state) => state.store.cacheItems);  
  const isFetchingQuotes = useSelector((state) => state.store.isFetchingQuotes);
  const [isQuoteCardExpanded, setIsQuoteCardExpanded] = useState(false);
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });

  const [isScrolling, setIsScrolling] = useState(true);
  const [scrollDirection, setScrollDirection] = useState('forward'); // Added direction state
  const carouselRef = useRef(null);
  const scrollIntervalRef = useRef(null);
  const isUserInteractingRef = useRef(false);
  const refreshQuotes = useSelector((state) => state.store.refreshQuotes);
  // Add a ref to track the selectedQuote for use in effects
  const selectedQuoteRef = useRef(selectedQuote);

  useEffect(() => {
    selectedQuoteRef.current = selectedQuote;
  }, [selectedQuote]);

  // Sort quotes: complete quotes on top, partial quotes below
  const sortedQuotes = quotes.slice().sort((a, b) => {
    // First: sort by availability percentage (descending)
    if (b.quotePresentationAvailabilityPercentage !== a.quotePresentationAvailabilityPercentage) {
      return b.quotePresentationAvailabilityPercentage - a.quotePresentationAvailabilityPercentage;
    }
    // Second: if availability percentage is the same, sort by total price (ascending)
    if (a.totalPrice !== b.totalPrice) {
      return a.totalPrice - b.totalPrice;
    }
    // Third: if total price is the same, sort by distance (ascending)
    return a.distanceMeters - b.distanceMeters;
  });

  const addAddressRoute =
    patient !== null ? '/store/cart/addresses' : '/store/cart/addresses/add';

  const isQuoteSelected = selectedQuote !== null;
  const isAddressProvided = selectedAddress !== null;
  const isPaymentMethodProvided = selectedPaymentMethod !== null;
  const isPatientLoggedIn = patient !== null;

  const pendingIconColor = '#808080'; // Grey for pending steps
  const completedIconColor = '#fdbf20'; // Original color for completed steps

  const launchQuotesView = () => {
    if (patient !== null) {
      navigate('/store/cart/quotes');
    } else {
      launchSignIn();
    }
  };

  const launchPaymentMethodsView = () => {
    if (patient !== null) {
      navigate('/store/cart/payment_methods');
    } else {
      launchSignIn();
    }
  };

  const launchSignIn = () => {
    nativeUtil.launchNativeLogin();
  };

  const redirectToLogin = () => {
    Swal.fire({
      title: 'Inicio de sesión',
      text: 'Está seguro de que desea dirigirse a la página de inicio de sesión?',
      icon: 'question',
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#fdbf20',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        if (cacheItems !== null && cacheItems.length > 0) {
          dispatch(setTempItems(cacheItems));
        }
        navigate('/store/login');
      }
    });
  };

  // Define handleMouseEnter to pause scrolling when mouse enters the carousel
  const handleMouseEnter = () => {
    isUserInteractingRef.current = true;
    setIsScrolling(false);
  };

  // Define handleMouseLeave to resume scrolling when mouse leaves the carousel
  const handleMouseLeave = () => {
    isUserInteractingRef.current = false;
    if (selectedQuoteRef.current === null) {
      setIsScrolling(true);
    }
  };

  // Define handleTouchStart to pause scrolling when touch starts on the carousel
  const handleTouchStart = () => {
    isUserInteractingRef.current = true;
    setIsScrolling(false);
  };

  // Define handleTouchEnd to resume scrolling when touch ends on the carousel
  const handleTouchEnd = () => {
    isUserInteractingRef.current = false;
    if (selectedQuoteRef.current === null) {
      setIsScrolling(true);
    }
  };

  // Modify handleQuoteClick to center the selected quote and stop scrolling
  const handleQuoteClick = async (quote, index) => {
    dispatch(changeSelectedQuote(quote));
    setIsScrolling(false);
    // Center the clicked quote in the carousel
    if (carouselRef.current) {
      const carousel = carouselRef.current;
      const quoteElement = carousel.children[index];
      const quoteLeft = quoteElement.offsetLeft;
      const quoteWidth = quoteElement.offsetWidth;
      const carouselCenter = carousel.clientWidth / 2;
      const scrollPosition = quoteLeft - carouselCenter + quoteWidth / 2;

      carousel.scrollTo({
        left: scrollPosition,
        behavior: 'smooth',
      });
    }
    if(!checkedQuotes.includes(quote.id)){
      const checkResponse = await signalingService.sendSignal('SEE_QUOTE', quote.id);
      if(checkResponse.successful && checkResponse.value){
        dispatch(addCheckedQuote(quote.id));
      }      
    }
  };

  // Add scroll event handler to detect manual scrolling
  const handleScroll = () => {
    if (!selectedQuoteRef.current) {
      setIsScrolling(false);
      clearInterval(scrollIntervalRef.current);
    }
  };

  // Attach scroll event listener
  useEffect(() => {
    const carousel = carouselRef.current;
    if (carousel) {
      carousel.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (carousel) {
        carousel.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  // Resume scrolling when user stops interacting
  const handleScrollEnd = () => {
    if (!selectedQuoteRef.current) {
      setIsScrolling(true);
    }
  };

  // Debounce scroll end detection
  useEffect(() => {
    const carousel = carouselRef.current;
    if (!carousel) return;

    let debounceTimeout;
    const onScroll = () => {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(() => {
        handleScrollEnd();
      }, 100);
    };

    carousel.addEventListener('scroll', onScroll);
    return () => {
      carousel.removeEventListener('scroll', onScroll);
      clearTimeout(debounceTimeout);
    };
  }, []);

  // Handle swipe to change selectedQuote
  const handleSwipe = () => {
    if (selectedQuoteRef.current && carouselRef.current) {
      const carousel = carouselRef.current;
      const visibleWidth = carousel.clientWidth;
      const scrollLeft = carousel.scrollLeft;
      const centerPosition = scrollLeft + visibleWidth / 2;

      // Find the quote closest to the center
      let closestIndex = 0;
      let closestDistance = Infinity;
      sortedQuotes.forEach((quote, index) => {
        const quoteElement = carousel.children[index];
        const quoteCenter = quoteElement.offsetLeft + quoteElement.offsetWidth / 2;
        const distance = Math.abs(quoteCenter - centerPosition);
        if (distance < closestDistance) {
          closestDistance = distance;
          closestIndex = index;
        }
      });

      const newSelectedQuote = sortedQuotes[closestIndex];
      if (newSelectedQuote && newSelectedQuote.id !== selectedQuoteRef.current.id) {
        dispatch(changeSelectedQuote(newSelectedQuote));
        // Center the new selected quote
        const quoteElement = carousel.children[closestIndex];
        const quoteLeft = quoteElement.offsetLeft;
        const quoteWidth = quoteElement.offsetWidth;
        const scrollPosition = quoteLeft - visibleWidth / 2 + quoteWidth / 2;

        carousel.scrollTo({
          left: scrollPosition,
          behavior: 'smooth',
        });
      }
    }
  };

  // Attach swipe event listeners
  useEffect(() => {
    const carousel = carouselRef.current;
    if (!carousel) return;

    let touchStartX = 0;
    let touchEndX = 0;

    const onTouchStartSwipe = (e) => {
      touchStartX = e.changedTouches[0].screenX;
    };

    const onTouchEndSwipe = (e) => {
      touchEndX = e.changedTouches[0].screenX;
      const deltaX = touchStartX - touchEndX;
      if (Math.abs(deltaX) > 50) {
        handleSwipe();
      }
    };

    carousel.addEventListener('touchstart', onTouchStartSwipe);
    carousel.addEventListener('touchend', onTouchEndSwipe);
    return () => {
      carousel.removeEventListener('touchstart', onTouchStartSwipe);
      carousel.removeEventListener('touchend', onTouchEndSwipe);
    };
  }, [sortedQuotes]);

  // Define the address text to display, ensuring we don't access properties of null
  const addressText =
    selectedAddress !== null
      ? selectedAddress.addressDetail
      : addresses.length === 0
      ? patient !== null
        ? '[ Agrega una dirección de entrega ]'
        : '[ Confirma la ubicación de entrega ]'
      : '[ Selecciona una dirección de entrega] ';

  // Adjusted carousel container style
  const carouselContainerStyle = {
    display: 'flex',
    overflowX: 'scroll',
    overflowY: 'hidden', // Disable vertical scrolling
    whiteSpace: 'nowrap',
    scrollBehavior: 'smooth',
    margin: '0 auto',
    width: '100%',
  };

  // Adjusted carousel item style
  const carouselItemStyle = {
    flexShrink: 0,
    width: '150px', // Ensures each item has a fixed width to cause overflow
    marginRight: '20px',
    marginLeft: '20px',
    cursor: 'pointer',
    transition: 'transform 0.3s, opacity 0.3s',
  };

  // Hide scrollbar for the carousel container
  const carouselContainerScrollbarStyle = {
    ...carouselContainerStyle,
    scrollbarWidth: 'none', // For Firefox
  };

  // For WebKit browsers
  const carouselContainerScrollbarClass = {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  };

  // Function to start automated scrolling
  const startScrolling = () => {
    if (carouselRef.current) {
      const carousel = carouselRef.current;
      clearInterval(scrollIntervalRef.current);
      scrollIntervalRef.current = setInterval(() => {
        if (!isUserInteractingRef.current && isScrolling) {
          if (scrollDirection === 'forward') {
            carousel.scrollLeft += 1;
            if (carousel.scrollLeft + carousel.clientWidth >= carousel.scrollWidth) {
              setScrollDirection('backward');
            }
          } else {
            carousel.scrollLeft -= 1;
            if (carousel.scrollLeft <= 0) {
              setScrollDirection('forward');
            }
          }
        }
      }, 20);
    }
  };

  useEffect(() => {
    startScrolling();
    return () => clearInterval(scrollIntervalRef.current);
  }, [scrollDirection, isScrolling, quotes]);

  // Handle clicks inside the carousel without selecting a quote
  const handleCarouselClick = (e) => {
    if (!e.target.closest('.order-quote')) { // Assuming OrderQuote has a class 'order-quote'
      setIsScrolling(false);
    }
  };

  useEffect(() => {
    const carousel = carouselRef.current;
    if (carousel) {
      carousel.addEventListener('click', handleCarouselClick);
    }
    return () => {
      if (carousel) {
        carousel.removeEventListener('click', handleCarouselClick);
      }
    };
  }, []);

  // Remove 'tableLayout: 'fixed'' to allow columns to auto-size
  const tableStyle = {
    width: '100%',
    tableLayout: 'fixed', // Removed this property
    borderButtom: '1px solid #dee2e6',
  };

  const orderItems = useSelector((state) => state.store.orderItems);
  const isCartEmpty = orderItems.length === 0;

  const getAuthCookie = () => {
    const match = document.cookie.match(new RegExp('(^| )' + 'Credentials' + '=([^;]+)'));
    if (match) return match[2];
    return null;
  };

  const handleRefreshQuotes = () => {
    if (isFetchingQuotes) return; // Prevent multiple fetches
    dispatch(setHasShownNoQuotesWarning(false)); // Reset the warning flag
    const authCookie = getAuthCookie();
    QuoteService.fetchQuotes({
      loggedInPatient: patient,
      authCookie: authCookie,
      cacheItems,
      orderItems,
      selectedAddress,
      addresses,
      dispatch,
    });
  };

  useEffect(() => {
    if(refreshQuotes){
      handleRefreshQuotes();
      dispatch(setRefreshQuotes(false));
    }
  }, [refreshQuotes]);

  return (
    <>
      <table style={tableStyle} className="table">
        <tbody>
          {/* Step 1: Select Quote */}
          <tr style={{borderTop: '1px solid #dee2e6'}}>
            <td style={{alignContent: 'center'}}>
              <MdLocalPharmacy
                style={{
                  color: isQuoteSelected ? completedIconColor : pendingIconColor,
                  width: '1.5em',
                  height: '1.5em',
                  marginLeft: isDesktop? '0.5em' : '1em',
                }}
              />
              {!isDesktop && <br />}
              <span style={{fontWeight: 'bold', fontSize: isDesktop ? '12pt' : '9pt', color: '#401409'}}>Farmacia:</span>
            </td>            
              
            <td
              style={{
                textAlign: 'center',
                width: '75%',
                alignContent: 'center',
                overflow: 'hidden',
              }}
            >               
              {!isFetchingQuotes && quotes.length > 0 && !isCartEmpty && (
                <div
                  ref={carouselRef}
                  style={carouselContainerScrollbarStyle}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onTouchStart={handleTouchStart}
                  onTouchEnd={handleTouchEnd}
                  // Apply scrollbar hiding styles
                  className="carousel-container"
                >
                  {sortedQuotes.map((quote, index) => (
                    <div
                      key={index}
                      onClick={() => handleQuoteClick(quote, index)}
                      style={{
                        ...carouselItemStyle,
                        opacity:
                          selectedQuote && selectedQuote.id === quote.id ? 1 : 0.6,
                        transform:
                          selectedQuote && selectedQuote.id === quote.id
                            ? 'scale(1.05)'
                            : 'scale(1)',
                      }}
                    >
                      <OrderQuote quote={quote} />
                    </div>
                  ))}
                  {/* Add the RefreshQuote component at the end of the carousel */}
                  <div
                    onClick={handleRefreshQuotes}
                    style={{
                      flexShrink: 0,
                      width: '150px',
                      marginRight: '20px',
                      marginLeft: '20px',
                      cursor: 'pointer',
                    }}
                  >
                    <RefreshQuote />
                  </div>
                </div>
              )}
              
              {!isFetchingQuotes && isCartEmpty && (
                <span
                  style={{
                    fontSize: isDesktop ? '11pt' : '9pt',
                    fontWeight: 'bold',
                    color: '#401409',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                  onClick={() => navigate('/store/search')}
                >
                  [ Agrega medicamentos al carrito para ver cotizaciones de farmacias cercanas ]
                </span>
              )}

              {isFetchingQuotes && (
                <div style={{ textAlign: 'center', color: '#401409', fontWeight: 'bold', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', alignContent: 'center'}}>
                  <Spinner />
                  Cargando cotizaciones
                </div>
              )}
            </td>
          </tr>

          {/* Step 2: Provide Address */}
          <tr onClick={() => navigate(addAddressRoute)}>
            <td style={{alignContent: 'center'}}>
              <FaMapMarkerAlt
                style={{
                  color: isAddressProvided ? completedIconColor : pendingIconColor,
                  width: '1.6em',
                  height: '1.6em',
                  marginLeft: isDesktop? '0.5em' : '1em',
                }}
              />                        
              {!isDesktop && <br />}
              <span style={{fontWeight: 'bold', fontSize: isDesktop ? '12pt' : '9pt', color: '#401409'}}>{patient !== null ? 'Dirección' : 'Ubicación'}:</span>
            </td>
            <td
              style={{
                fontSize: isDesktop? '11pt' : '9pt',
                fontWeight: 'bold',
                textAlign: 'center',
                alignContent: 'center',
              }}
            >
              <Link to={addAddressRoute}>
                <span style={{ color: '#401409' }}>{addressText}</span>
              </Link>
            </td>
          </tr>

          {/* Step 3: Specify Payment Method */}
          <tr onClick={() => launchPaymentMethodsView()}>
            <td style={{alignContent: 'center'}}>
              <FaRegCreditCard
                style={{
                  color: isPaymentMethodProvided ? completedIconColor : pendingIconColor,
                  width: '1.6em',
                  height: '1.6em',
                  marginLeft: isDesktop? '0.5em' : '1em',
                }}
              />            
              {!isDesktop && <br />}
              <span style={{fontWeight: 'bold', fontSize: isDesktop ? '12pt' : '9pt', color: '#401409', marginLeft: isDesktop? '0.1em' : '1em'}}>Pago:</span>
            </td>
            <td
              style={{
                fontSize: '11pt',
                fontWeight: 'bold',
                textAlign: 'center',    
                alignContent: 'center',            
              }}
            >
              {patient !== null ? (
                <Link to="/store/cart/payment_methods">
                  <span style={{ color: '#401409' }}>
                    {paymentMethods.length === 0 || selectedPaymentMethod === null
                      ? '[ Agrega un método de pago ]'
                      : selectedPaymentMethod.vendorDescription +
                        ' - ' +
                        selectedPaymentMethod.maskedNumber}
                  </span>
                </Link>
              ) : (
                <Link to="/store/cart/payment_methods/pay">
                  {selectedPaymentMethod === null ? (
                    <span
                      style={{
                        fontSize: isDesktop? '11pt' : '9pt', 
                        fontWeight: 'bold',
                        textAlign: 'left',
                        color: '#401409',
                      }}
                      onClick={() => launchSignIn()}
                    >
                      [ Ingresa un método de pago ]
                    </span>
                  ) : (
                    <span
                      style={{
                        fontSize: isDesktop? '11pt' : '10pt', 
                        fontWeight: 'bold',
                        textAlign: 'left',
                        color: '#401409',
                      }}
                    >
                      {selectedPaymentMethod.maskedNumber}
                    </span>
                  )}
                </Link>
              )}
            </td>
          </tr>
          {/* Profile Section */}
          <tr>
            <td style={{alignContent: 'center'}}>
              <FaUserAlt
                style={{
                  color: isPatientLoggedIn ? completedIconColor : pendingIconColor,
                  width: '1.6em',
                  height: '1.6em',
                  marginLeft: isDesktop? '0.5em' : '1em',
                }}
              />
              {!isDesktop && <br />}
              <span style={{fontWeight: 'bold', fontSize: isDesktop ? '12pt' : '9pt', color: '#401409', marginLeft: isDesktop? '0.5em' : '1em'}}>Perfil:</span>
            </td>
            <td
              style={{
                fontSize: isDesktop? '11pt' : '9pt',
                fontWeight: 'bold',
                textAlign: 'center',
                alignContent: 'center',
                color: '#0d6efd',
              }}
            >
              {patient !== null ? (
                <span style={{ textAlign: 'left', color: '#401409' }}>
                  {patient.firstName + ' ' + patient.lastName}
                </span>
              ) : (
                <span
                  style={{ color: '#401409', textDecoration: 'underline' }}
                  onClick={() => redirectToLogin()}
                >
                  (Opcional)<br />
                  [ Inicia sesión para una mejor experiencia ]
                </span>
              )}
            </td>
          </tr>          
        </tbody>
      </table>

      {/* Add this style block to hide scrollbars */}
      <style>
        {`
          .carousel-container::-webkit-scrollbar {
            display: none;
          }
        `}
      </style>
    </>
  );
};
const Spinner = styled.div`
    border: 4px solid #f3f3f3;
    border-top: 4px solid #e0a800;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: ${spin} 2s linear infinite;
    margin-right: 10px;

    @media (max-width: 480px) {
        width: 16px;
        height: 16px;
        border-width: 3px;
    }
`;
export default PurchaseInfo;
