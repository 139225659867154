import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import ShoppingCartList from '../../components/store/cart/ShoppingCartList';
import PurchaseInfo from '../../components/store/payment/PurchaseInfo';
import { ShoppingCartService } from '../../services/drsbee/data/ShoppingCartService';
import {
  setCurrentOrder,
  setOrderItems,
  setCacheItems,
  addPrescriptionToOrder,
  setPerformRequote,
  setIsFetchingQuotes,
  selectIsFetchingQuotes,
} from '../../reducers/StoreSlice';
import { useAuth } from '../../context/AuthContext';
import Swal from 'sweetalert2';
import styled, { keyframes } from 'styled-components';
import PageWrapper from '../../components/shared/PageWrapper';
import { RequoteService } from '../../services/drsbee/data/RequoteService';
import { QuoteService } from '../../services/drsbee/data/QuoteService';

const shoppingCartService = new ShoppingCartService();

const CartView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { privateKey } = useParams();
  const orderItems = useSelector((state) => state.store.orderItems);
  const cacheItems = useSelector((state) => state.store.cacheItems);
  const selectedAddress = useSelector((state) => state.store.selectedAddress);
  const selectedPaymentMethod = useSelector((state) => state.store.selectedPaymentMethod);
  const selectedQuote = useSelector((state) => state.store.selectedQuote);
  const quotes = useSelector((state) => state.store.quotes);
  const { authCookie } = useAuth();
  const loggedInPatient = useSelector((state) => state.store.loggedInPatient);
  const addedPrescriptions = useSelector((state) => state.store.addedPrescriptions);
  const [authenticatedDepsReady, setAuthenticatedDepsReady] = useState(false);
  const [guestDepsReady, setGuestDepsReady] = useState(false);
  const performRequote = useSelector((state) => state.store.performRequote);
  const addresses = useSelector((state) => state.store.addresses);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isFetchingQuotes = useSelector(selectIsFetchingQuotes); // Add selector for isFetchingQuotes

  // UseRef flags to prevent multiple calls
  const authenticatedPrescriptionAdded = useRef(false);
  const guestPrescriptionAdded = useRef(false);

  // Ref to store the timeout ID
  const requoteTimeoutRef = useRef(null);

  const confirmOrder = async () => {
    setIsSubmitting(true);
    if (selectedQuote && selectedAddress && selectedPaymentMethod) {
      try {
        let response = await shoppingCartService.createOrderFromQuote(
          selectedQuote.id,
          loggedInPatient && authCookie ? authCookie : null
        );

        if (response.successful && response.value !== null) {
          dispatch(setCurrentOrder(response.value));
          navigate('/store/cart/checkout');
        } else if (response.errorMessages[0].message === 'Orden expirada') {
          await Swal.fire({
            title: 'Cotización expirada',
            text: 'Tu cotización ha expirado, se volverá a generar la cotización para que puedas continuar.',
            icon: 'warning',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#fdbf20',
          });

          await RequoteService.requoteOrder(
            {
              loggedInPatient,
              authCookie,
              cacheItems,
              orderItems,
              selectedQuote,
              selectedAddress,
              addresses,
              dispatch,
              isRequoteTriggeredByCartChange: false,
              isRequoteTriggeredByLocationChange: false,
            },
            navigate
          );

          // Retry order creation
          response = await shoppingCartService.createOrderFromQuote(
            selectedQuote.id,
            loggedInPatient && authCookie ? authCookie : null
          );

          if (response.successful && response.value !== null) {
            dispatch(setCurrentOrder(response.value));
            navigate('/store/cart/checkout');
          } else {
            Swal.fire({
              title: 'Error',
              text: 'No se pudo crear el pedido después de actualizar la cotización.',
              icon: 'error',
              confirmButtonText: 'Aceptar',
              confirmButtonColor: '#fdbf20',
            });
          }
        } else {
          Swal.fire({
            title: 'Error',
            text: 'No se pudo crear el pedido.',
            icon: 'error',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#fdbf20',
          });
        }
      } catch (error) {
        console.error('Error creating order:', error);
        Swal.fire({
          title: 'Error',
          text: 'Ocurrió un error al crear el pedido.',
          icon: 'error',
          confirmButtonText: 'Aceptar',
          confirmButtonColor: '#fdbf20',
        });
      } finally {
        setIsSubmitting(false);
      }
    } else {
      let pendingStepsNum = 1;
      let pendingSteps = [];
      if (selectedQuote === null) {
        pendingSteps.push(pendingStepsNum++ + '. Selecciona una farmacia y cotización');
      }
      if (selectedAddress === null) {
        pendingSteps.push(pendingStepsNum++ + '. Agrega una dirección de entrega');
      }
      if (selectedPaymentMethod === null) {
        pendingSteps.push(pendingStepsNum++ + '. Especifica un método de pago');
      }
      Swal.fire({
        title: 'Pasos pendientes',
        html:
          'Por favor completa los siguientes pasos antes de confirmar el pedido:<br>' +
          pendingSteps.join('<br>'),
        icon: 'warning',
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#fdbf20',
      });
      setIsSubmitting(false);
    }
  };

  const showDuplicatePrescriptionAlert = () => {
    Swal.fire({
      title: 'Prescripción previamente agregada',
      text: 'Esta prescripción ya ha sido agregada al carrito.',
      icon: 'info',
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#fdbf20',
    }).then((result) => {
      if (result.isConfirmed) {
        navigate('/store/cart');
      }
    });
  };

  const isLoadedToCart = (privateKey) => {
    return addedPrescriptions.some(
      (prescription) => prescription.privateKey === privateKey
    );
  };

  useEffect(() => {
    if (
      privateKey !== undefined &&
      privateKey !== null &&
      authCookie !== null &&
      loggedInPatient !== null
    ) {
      setAuthenticatedDepsReady(true);
    }
  }, [privateKey, authCookie, loggedInPatient]);

  useEffect(() => {
    if (
      privateKey !== undefined &&
      privateKey !== null &&
      loggedInPatient === null &&
      authCookie === null
    ) {
      setGuestDepsReady(true);
    }
  }, [privateKey, loggedInPatient, authCookie]);

  useEffect(() => {
    if (cacheItems !== undefined && orderItems !== undefined) {
      if (cacheItems.length > orderItems.length) {
        dispatch(setOrderItems(cacheItems));
      }
    }
  }, [cacheItems, orderItems, dispatch]);

  useEffect(() => {
    if (authenticatedDepsReady && privateKey && !authenticatedPrescriptionAdded.current) {
      authenticatedPrescriptionAdded.current = true; // Set the flag to prevent re-execution
      if (isLoadedToCart(privateKey)) {
        showDuplicatePrescriptionAlert();
      } else {
        if (loggedInPatient !== null && authCookie !== null) {
          shoppingCartService
            .addPrescriptionToCart(privateKey, authCookie)
            .then((response) => {
              if (response.successful && response.value !== null) {
                dispatch(
                  setOrderItems(response.value.items.filter((item) => item.status === '1'))
                );
                const prescription = response.value;
                const activeItems = prescription.items.filter((item) => item.status === '1');
                const itemsQty = activeItems.filter(
                  (item) => item.prescriptionId !== null && item.prescriptionId.toString() === prescription.prescriptionIdAccess
                ).length;
                const prescriptionObject = {
                  prescriptionId: prescription.prescriptionIdAccess,
                  privateKey: privateKey,
                  itemsQty: itemsQty,
                  previewUrl: prescription.previewFile ? prescription.previewFile.url : null,
                };
                dispatch(addPrescriptionToOrder(prescriptionObject));

                // Immediately show success Swal
                Swal.fire({
                  title: '¡Prescripción cargada!',
                  html: 'Has cargado correctamente una prescripción al carrito.<br />Para ver las prescripciones agregadas, da click en el menú de la abeja en la esquina superior izquierda.',
                  icon: 'success',
                  confirmButtonText: 'Continuar al carrito',
                  confirmButtonColor: '#fdbf20',
                }).then((result) => {
                  if (result.isConfirmed) {
                    navigate('/store/cart');
                  }
                });

                // Fetch quotes in the background
                const params = {
                  loggedInPatient,
                  authCookie,
                  cacheItems,
                  orderItems: response.value.items.filter((item) => item.status === '1'),
                  selectedAddress,
                  addresses,
                  dispatch,
                };
                QuoteService.fetchQuotes(params);
              }
            });
        }
      }
    }
  }, [
    authenticatedDepsReady,
    privateKey,
    authCookie,
    loggedInPatient,
    dispatch,
    navigate,
    cacheItems,
    selectedAddress,
    addresses,
  ]);

  useEffect(() => {
    if (guestDepsReady && privateKey && !guestPrescriptionAdded.current) {
      guestPrescriptionAdded.current = true; // Set the flag to prevent re-execution
      if (isLoadedToCart(privateKey)) {
        showDuplicatePrescriptionAlert();
      } else {
        const currentDate = new Date().toISOString();
        const cachedCart = {
          id: '',
          lastModificationDate: currentDate,
          createDate: currentDate,
          subtotal: null,
          discount: null,
          tax: null,
          total: null,
          items: cacheItems,
        };
        shoppingCartService
          .addPrescriptionToCachedCart(privateKey, cachedCart)
          .then((response) => {
            if (response.successful && response.value !== null) {
              dispatch(setCacheItems(response.value.items));
              dispatch(setOrderItems(response.value.items));
              const prescription = response.value;
              const itemsQty = prescription.items.filter(
                (item) => item.prescriptionId !== null && item.prescriptionId.toString() === prescription.prescriptionIdAccess
              ).length;
              const prescriptionObject = {
                prescriptionId: prescription.prescriptionIdAccess,
                privateKey: privateKey,
                itemsQty: itemsQty,
                previewUrl: prescription.previewFile ? prescription.previewFile.url : null,
              };
              dispatch(addPrescriptionToOrder(prescriptionObject));

              // Immediately show success Swal
              Swal.fire({
                title: '¡Prescripción cargada!',
                html: 'Has cargado correctamente una prescripción al Carrito de Compras de DrsBee.<br />Para ver las prescripciones agregadas, da click en el menú de la abeja, arriba a la izquierda.',
                icon: 'success',
                confirmButtonText: 'Continuar al carrito',
                confirmButtonColor: '#fdbf20',
              }).then((result) => {
                if (result.isConfirmed) {
                  navigate('/store/cart');
                }
              });

              // Fetch quotes in the background
              const params = {
                loggedInPatient,
                authCookie,
                cacheItems: response.value.items,
                orderItems: response.value.items,
                selectedAddress,
                addresses,
                dispatch,
              };
              QuoteService.fetchQuotes(params);
            }
          });
      }
    }
  }, [
    guestDepsReady,
    privateKey,
    cacheItems,
    dispatch,
    navigate,
    selectedAddress,
    addresses,
  ]);

  // Adjusted useEffect to prevent multiple triggers
  useEffect(() => {
    if (performRequote) {
      RequoteService.requoteOrder(
        {
          loggedInPatient,
          authCookie,
          cacheItems,
          orderItems,
          selectedQuote,
          selectedAddress,
          addresses,
          dispatch,
          isRequoteTriggeredByCartChange: false,
          isRequoteTriggeredByLocationChange: true,
        },
        navigate
      ).then(() => {
        dispatch(setPerformRequote(false));
      });
    }
  }, [performRequote, dispatch, loggedInPatient, authCookie, navigate, cacheItems, orderItems, selectedQuote, selectedAddress, addresses]);

  // useEffect to track selectedQuote's expirationDate
  useEffect(() => {
    // Clear any existing timeout
    if (requoteTimeoutRef.current) {
      clearTimeout(requoteTimeoutRef.current);
    }

    if (selectedQuote && selectedQuote.expirationDate) {
      const expirationTime = new Date(selectedQuote.expirationDate).getTime();
      const currentTime = Date.now();
      const timeUntilExpiration = expirationTime - currentTime;

      if (timeUntilExpiration > 0) {
        // Set timeout to trigger requote when expiration date is reached
        requoteTimeoutRef.current = setTimeout(async () => {
          await RequoteService.requoteOrder(
            {
              loggedInPatient,
              authCookie,
              cacheItems,
              orderItems,
              selectedQuote,
              selectedAddress,
              addresses,
              dispatch,
              isRequoteTriggeredByCartChange: false,
              isRequoteTriggeredByLocationChange: false,
            },
            navigate
          );
        }, timeUntilExpiration);
      } else {
        // If expirationDate has already passed, trigger requote immediately
        RequoteService.requoteOrder(
          {
            loggedInPatient,
            authCookie,
            cacheItems,
            orderItems,
            selectedQuote,
            selectedAddress,
            addresses,
            dispatch,
            isRequoteTriggeredByCartChange: false,
            isRequoteTriggeredByLocationChange: false,
          },
          navigate
        );
      }
    }

    // Cleanup on unmount or when selectedQuote changes
    return () => {
      if (requoteTimeoutRef.current) {
        clearTimeout(requoteTimeoutRef.current);
      }
    };
  }, [selectedQuote, loggedInPatient, authCookie, cacheItems, orderItems, selectedAddress, addresses, dispatch, navigate]);

  useEffect(() => {
    const fetchQuotesOnLoad = async () => {
      const items = loggedInPatient ? orderItems : cacheItems;
      if (items && items.length > 0) {
        const params = {
          loggedInPatient,
          authCookie,
          cacheItems: loggedInPatient ? null : items,
          orderItems: loggedInPatient ? items : null,
          selectedAddress,
          addresses,
          dispatch,
        };
        await QuoteService.fetchQuotes(params);
      }
    };

    // Only trigger if quotes are not already fetched and not currently fetching
    if (
      (quotes === null || quotes.length === 0) &&
      (orderItems.length > 0 || cacheItems.length > 0) &&
      !isFetchingQuotes
    ) {
      fetchQuotesOnLoad();
    }
  }, [
    loggedInPatient,
    authCookie,
    orderItems,
    cacheItems,
    quotes,
    selectedAddress,
    addresses,
    dispatch,
    isFetchingQuotes, // Add isFetchingQuotes to dependencies
  ]);

  const loadPrescriptionToCart = async () => {
    const params = {
      loggedInPatient,
      authCookie,
      cacheItems,
      orderItems,
      selectedAddress,
      addresses,
      dispatch,
    };
    await QuoteService.fetchQuotes(params);
  };

  return (
    <PageWrapper selectedMenuOption="cart" orderItemsCount={orderItems.length}>
      <ShoppingCartListContainer>
        <ShoppingCartList />
      </ShoppingCartListContainer>
      <PurchaseInfoContainer>
        <PurchaseInfo quotes={quotes} />
        <div className="d-flex flex-row justify-content-center">
          <Button
            style={{
              borderRadius: '0.8em',
              width: '12em',
              marginBottom: '1em',
              marginTop: '0em!important',
              backgroundColor:
                selectedAddress === null ||
                selectedPaymentMethod === null ||
                selectedQuote === null
                  ? '#4b3834'
                  : '#fdbf20',
              color:
                selectedAddress === null ||
                selectedPaymentMethod === null ||
                selectedQuote === null
                  ? '#FFFFFF'
                  : '#401409',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: !isSubmitting? '1em' : '0.8em',
            }}
            variant="warning"
            onClick={() => confirmOrder()}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <>
                <Spinner />
                Confirmando...
              </>
            ) : (
              "Confirmar pedido"
            )}
          </Button>
        </div>
      </PurchaseInfoContainer>
    </PageWrapper>
  );
};

const ShoppingCartListContainer = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const PurchaseInfoContainer = styled.div`
  bottom: 0;
  left: 0;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #e0a800;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: ${spin} 2s linear infinite;
  margin-right: 10px;

  @media (max-width: 480px) {
    width: 16px;
    height: 16px;
    border-width: 3px;
  }
`;

export default CartView;