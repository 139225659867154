import React from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  changeSelectedAddress,
  setAddresses,
  setPerformRequote,
} from '../../../reducers/StoreSlice';
import { FaMapMarked } from 'react-icons/fa';
import { AddressService } from '../../../services/drsbee/data/AddressService';
import { useAuth } from '../../../context/AuthContext';
import { NativeUtilities } from '../../../utility/NativeUtilities';

const nativeUtil = new NativeUtilities();
const addressService = new AddressService();

const AddressList = () => {
  const dispatch = useDispatch();
  const authCookie = useAuth();
  const addresses = useSelector((state) => state.store.addresses);
  const selectedAddress = useSelector((state) => state.store.selectedAddress);
  const navigate = useNavigate();
  const loggedInPatient = useSelector((state) => state.store.loggedInPatient);
  const orderItems = useSelector((state) => state.store.orderItems);  
  const deleteAddress = () => {
    // Remove the selected address from the Redux state
    const updatedAddresses = addresses.filter(
      (address) => address.id !== selectedAddress.id
    );
    dispatch(setAddresses(updatedAddresses));

    // Check if there are any addresses left
    if (updatedAddresses.length > 0) {
      // Set the selected address to the first address in the list
      const newSelectedAddress = updatedAddresses[0];
      dispatch(changeSelectedAddress(newSelectedAddress));
      window.localStorage.setItem(
        'selectedAddress',
        JSON.stringify(newSelectedAddress)
      );
    } else {
      // No addresses left, set selected address to null and remove from local storage
      dispatch(changeSelectedAddress(null));
      window.localStorage.removeItem('selectedAddress');
    }
  };

  const handleAddressChange = (event, address) => {
    dispatch(changeSelectedAddress(address));
    window.localStorage.setItem('selectedAddress', JSON.stringify(address));
    if (orderItems.length > 0) {
      dispatch(setPerformRequote(true));
    }    
    navigate('/store/cart/');
  };

  const handleDeleteAddress = () => {
    if (loggedInPatient !== null) {
      addressService.deleteAddress(selectedAddress, authCookie).then((response) => {
        if (response.successful) {
          deleteAddress();
        }
      });
    } else {
      deleteAddress();
    }
  };

  return (
    <div>
      <div
        className="d-flex flex-row justify-content-left align-items-center"
        style={{ overflowY: 'scroll' }}
      >
        <Link to="/store/cart" className="mb-3">
          <Button
            variant="primary m-3"
            style={{ backgroundColor: '#401409', color: '#FFFFFF' }}
          >
            Volver
          </Button>
        </Link>
        <FaMapMarked
          style={{ height: 35, width: 35, marginBottom: '1.2em' }}
        />
        <h2 style={{ marginBottom: '0.8em', marginRight: '2em' }}>
          Direcciones
        </h2>
      </div>
      {addresses.length === 0 ? (
        <Card>
          <Card.Body>
            <Card.Text>No tienes direcciones registradas.</Card.Text>
          </Card.Body>
        </Card>
      ) : (
        addresses.map((address, index) => (
          <Card
            key={index}
            className="mb-3"
            onClick={(e) => handleAddressChange(e, address)}
          >
            <Card.Header style={{ textAlign: 'center' }}>
              <Form.Check
                type="radio"
                name="address"
                id={`address-${index}`}
                style={{ fontSize: '14pt' }}
                label={
                  address.deliveryPlaceDetail &&                  
                  address.deliveryPlaceDetail !== 'Dirección sin detalles'
                    ? address.deliveryPlaceDetail
                    : address.addressDetail || 'Dirección sin detalles'
                }
                checked={
                  selectedAddress.id === address.id || addresses[0] === address
                }
                onChange={(e) => handleAddressChange(e, address)}
              />
            </Card.Header>
            <Card.Body>
              <p style={{ fontSize: '9pt' }}>{address.addressDetail}</p>
              {address.phone && (
                <p style={{ fontSize: '11pt' }}>Teléfono: {address.phone}</p>
              )}
            </Card.Body>
          </Card>
        ))
      )}

      <div className="mt-3 d-flex flex-row justify-content-between p-5">
        <Link to="/store/cart/addresses/add">
          <Button
            variant="warning"
            style={{ backgroundColor: '#fdbf20', color: '#401409' }}
          >
            Agregar
          </Button>
        </Link>
        {addresses.length > 0 && (
          <Button variant="danger" onClick={handleDeleteAddress}>
            Eliminar
          </Button>
        )}
      </div>
    </div>
  );
};

export default AddressList;
