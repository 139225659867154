// QuoteTimer.js

import React, { useState, useEffect, useRef } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const QuoteTimer = ({ expirationDate, onExpire }) => {
  const [remainingTime, setRemainingTime] = useState(0);
  const [totalDuration, setTotalDuration] = useState(0);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (!expirationDate) return;

    const expiryTime = new Date(expirationDate).getTime();
    const initialRemainingTime = expiryTime - Date.now();
    setRemainingTime(initialRemainingTime > 0 ? initialRemainingTime : 0);
    setTotalDuration(initialRemainingTime > 0 ? initialRemainingTime : 0);

    intervalRef.current = setInterval(() => {
      const now = Date.now();
      const timeLeft = expiryTime - now;
      setRemainingTime(timeLeft > 0 ? timeLeft : 0);

      if (timeLeft <= 0) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
        if (onExpire) {
          onExpire();
        }
      }
    }, 1000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [expirationDate, onExpire]);

  // Invert the percentage to make the red section increase clockwise
  const percentage = totalDuration
    ? 100 - (remainingTime / totalDuration) * 100
    : 100;

  const minutes = Math.floor(remainingTime / 60000);
  const seconds = Math.floor((remainingTime % 60000) / 1000);

  return (
    <div style={{ width: '2.2em', height: '2.2em', marginRight: '1em', marginLeft: '-1.5em' }}>
      <CircularProgressbar
        value={percentage}
        text={`${minutes}:${seconds < 10 ? '0' : ''}${seconds}`}
        styles={buildStyles({
          rotation: 0, // Start from right side (3 o'clock)
          pathTransitionDuration: 0.5,
          pathColor: '#dd9f00', // Red color for the path (increasing)
          trailColor: '#7acdcb', // Green color for the trail (decreasing)
          textColor: '#000',
          textSize: '1.5em',
        })}
        strokeWidth={10}
      />
    </div>
  );
};

export default QuoteTimer;
