import { CURRENT_BACKEND } from "../config/BackendConfiguration";
export class SignalingService{
    constructor(){
        this.backendConfiguration = CURRENT_BACKEND;
    }
    async sendSignal(signalEvent, signalParam){
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
            credentials: "include",
        };
        const response = await fetch(this.backendConfiguration.baseUrl+"/check/event?eventType="+signalEvent+"&param="+signalParam, requestOptions)
        return this._handleResponse(response);
    }
    async _handleResponse(response) {
        if (response.ok) {
            try {
                return await response.json();
            } catch (jsonError) {
                return {};
            }
        } else if (response.status === 401) {
            throw new Error("Unauthorized");
        } else {
            let errorMessage = `HTTP error! Status: ${response.status}`;
            try {
                const errorJson = await response.json();
                if (errorJson && errorJson.message) {
                    errorMessage = errorJson.message;
                }
            } catch (e) {
                const errorText = await response.text();
                if (errorText) {
                    errorMessage = errorText;
                }
            }
            throw new Error(errorMessage);
        }
    }

}