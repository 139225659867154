import { useState } from 'react';
import styled from 'styled-components';
import {
    FaMapMarkerAlt,
    FaSearchPlus,
    FaHistory,
    FaFilePrescription,
    FaQuestionCircle,
    FaSignOutAlt,
    FaPrescription,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import ShoppingCartCounter from '../store/cart/ShoppingCartCounter';
import { NativeUtilities } from '../../utility/NativeUtilities';
import AppLauncher from '../store/cart/AppLauncher';
import { useSelector, useDispatch } from 'react-redux';
import { resetState, performLogout, addCheckedPrescription } from '../../reducers/StoreSlice';
import { useAuth } from '../../context/AuthContext';
import { AuthService } from '../../services/drsbee/auth/AuthService';
import Swal from 'sweetalert2';
import { PatientService } from '../../services/drsbee/data/PatientService';
import { SignalingService } from '../../services/drsbee/data/SignalingService';
const nativeUtil = new NativeUtilities();
const authService = new AuthService();
const patientService = new PatientService();
const signalingService = new SignalingService();
const PageWrapper = ({ children, selectedMenuOption, orderItemsCount }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loggedInPatient = useSelector((state) => state.store.loggedInPatient);
    const addedPrescriptions = useSelector((state) => state.store.addedPrescriptions);
    const { authCookie, setAuthCookie } = useAuth();
    const [showDropdown, setShowDropdown] = useState(false);
    const [showPrescriptionsDropdown, setShowPrescriptionsDropdown] = useState(false);
    const checkedPrescriptions = useSelector((state) => state.store.checkedPrescriptions);
    const handleMouseEnter = () => {
        if (!nativeUtil.isRunningOnWebView()) {
            setShowDropdown(true);
        }
    };

    const handleMouseLeave = () => {
        setShowDropdown(false);
    };

    const appLauncherAction = () => {
        if (nativeUtil.isRunningOnWebView()) {
            console.log("Launching app lobby");
            nativeUtil.launchAppLobby();
        } else {
            setShowDropdown(!showDropdown);
        }
    };
    
    const logout = () => {
        // Clear localStorage and sessionStorage
        window.localStorage.clear();
        window.sessionStorage.clear();

        // Reset Redux store to its initial state        
        dispatch(performLogout());      
        dispatch(resetState());  
        authService.logout().then(() => {
            nativeUtil.deleteAllCookies();
            setAuthCookie(null);            
            navigate("/store/login");
        });       
    };

    const navigateToHistory = () => {
        if (loggedInPatient !== null) {
            navigate("/store/order/history");
        } else {
            redirectToLogin();
        }
    };

    const redirectToLogin = () => {
        Swal.fire({
            title: 'Se requiere autenticación',
            text: 'Para acceder a esta funcionalidad, primero debe iniciar sesión.',
            icon: 'warning',
            confirmButtonText: 'Iniciar sesión',
            confirmButtonColor: '#fdbf20',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                window.localStorage.clear();
                window.sessionStorage.clear();
                dispatch(performLogout());
                dispatch(resetState());
                navigate("/store/login");
            }
        });
    };

    const showPrescriptionPreview = async (previewUrl, privateKey) => {
        Swal.fire({
            imageUrl: previewUrl,
            imageAlt: 'Prescription Preview',
            showConfirmButton: true,
            confirmButtonText: 'Cerrar',
            confirmButtonColor: '#fdbf20',
        });
        if(!checkedPrescriptions.includes(privateKey)){
            const checkResponse = await signalingService.sendSignal('SEE_PDF', privateKey);
            if (checkResponse.successful && checkResponse.value) {
                dispatch(addCheckedPrescription(privateKey));
            }
        }
    };

    return (
        <Container>
            <IconBar>
                <AppLauncherWrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <AppLauncher
                        onClick={() => appLauncherAction()}
                        style={{
                            height: 38,
                            width: 50,
                            backgroundColor: "transparent"
                        }}
                    />
                    {showDropdown && (
                        <DropdownMenu>
                            {addedPrescriptions.length > 0 && (
                                <div style={{ position: 'relative' }}>
                                    <DropdownAnchor
                                        onMouseEnter={() => setShowPrescriptionsDropdown(true)}
                                        onMouseLeave={() => setShowPrescriptionsDropdown(false)}
                                    >
                                        <FaPrescription style={{marginRight: '0.5em'}} /> Prescripciones
                                    </DropdownAnchor>

                                    {showPrescriptionsDropdown && (
                                        <DropdownMenu
                                            onMouseEnter={() => setShowPrescriptionsDropdown(true)}
                                            onMouseLeave={() => setShowPrescriptionsDropdown(false)}
                                        >
                                            {addedPrescriptions.map((prescription) => (
                                                <DropdownItem
                                                    key={prescription.prescriptionId}
                                                    onClick={() => showPrescriptionPreview(prescription.previewUrl, prescription.privateKey)}
                                                >
                                                    <FaFilePrescription style={{ marginRight: '0.5em' }} />
                                                    {prescription.prescriptionId}
                                                </DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    )}
                                </div>
                            )}
                            
                            <DropdownAnchor href="https://apololab.atlassian.net/servicedesk/customer/portal/1" target="_blank" rel="noopener noreferrer">
                                <FaQuestionCircle style={{ marginRight: '0.5em'}} />
                                Ayuda
                            </DropdownAnchor>
                            {loggedInPatient !== null && (
                                <DropdownItem onClick={logout}>
                                <FaSignOutAlt style={{ marginRight: '0.5em'}} />
                                    Cerrar Sesión
                                </DropdownItem>
                            )}
                            
                        </DropdownMenu>
                    )}
                </AppLauncherWrapper>
                <Link to="/store/search">
                    <FaSearchPlus
                        style={{
                            marginLeft: '-1em',
                            height: 53,
                            width: 37,
                            color: selectedMenuOption === 'search' ? "#fdbf20" : "#401409",
                            backgroundColor: "transparent"
                        }}
                    />
                </Link>
                <Link to="/store/cart/addresses">
                    <FaMapMarkerAlt
                        style={{
                            height: 43,
                            width: 27,
                            color: selectedMenuOption === 'addresses' ? "#fdbf20" : "#401409",
                            backgroundColor: "transparent"
                        }}
                    />
                </Link>
                <Link to="/store/cart" style={{ marginBottom: '1.9em' }}>
                    <ShoppingCartCounterContainer>
                        <ShoppingCartCounter itemCount={orderItemsCount} selected={selectedMenuOption === 'cart'} />
                    </ShoppingCartCounterContainer>
                </Link>
                <FaHistory
                    style={{
                        marginLeft: '2em',
                        height: 53,
                        width: 37,
                        color: selectedMenuOption === 'history' ? "#fdbf20" : "#401409",
                        backgroundColor: "transparent"
                    }}
                    onClick={() => navigateToHistory()}
                />
                {/* </Link> */}
            </IconBar>
            <RectangleStack>
                <CenteredRectangle>
                    {children}
                </CenteredRectangle>
            </RectangleStack>
        </Container>
    );
};

const AppLauncherWrapper = styled.div`
    position: relative;
    display: inline-block;
`;

const DropdownMenu = styled.div`
    position: absolute;
    top: 40px;
    left: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 0.5em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 0.5em 1em;
`;

const DropdownLink = styled(Link)`
    display: flex;
    align-items: center;
    padding: 0.5em 0;
    color: #401409;
    text-decoration: none;
    &:hover {
        color: #fdbf20;
    }
`;

const DropdownAnchor = styled.a`
    display: flex;
    align-items: center;
    padding: 0.5em 0;
    color: #401409;
    text-decoration: none;
    &:hover {
        color: #fdbf20;
    }
`;

const DropdownItem = styled.div`
    display: flex;
    align-items: center;
    padding: 0.5em 0;
    color: #401409;
    cursor: pointer;
    &:hover {
        color: #fdbf20;
    }
`;

const ShoppingCartCounterContainer = styled.div`
    margin-bottom: 0.5em;
    z-index: 999;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    min-height: 100vh;
    justify-content: center;
`;

const IconBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-right: 2em;
    padding-left: 2em;
`;

const RectangleStack = styled.div`
    flex: 1;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
    padding: 0.2em;
`;

const CenteredRectangle = styled.div`
    position: absolute;
    height: 93vh;
    width: 96%;
    border-width: 2px;
    border-radius: 1em;
    border-color: #fdbf20;
    margin-bottom: 1em;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    border-style: solid;
    overflow-y: auto;
`;

export default PageWrapper;
