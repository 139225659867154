import Swal from 'sweetalert2';
import { ShoppingCartService } from './ShoppingCartService';
import { setQuotes, setIsFetchingQuotes, setHasShownNoQuotesWarning } from '../../../reducers/StoreSlice'; // Added setHasShownNoQuotesWarning

const shoppingCartService = new ShoppingCartService();

export const QuoteService = {
  fetchQuotes: async (
    {
      loggedInPatient,
      authCookie,
      cacheItems,
      orderItems,
      selectedAddress,
      addresses,
      dispatch,
    }
  ) => {
    dispatch(setIsFetchingQuotes(true));
    // Determine if there are items to quote
    const itemsToQuote = loggedInPatient ? orderItems : cacheItems;

    if (!itemsToQuote || itemsToQuote.length === 0) {
      dispatch(setIsFetchingQuotes(false));
      return null;
    }

    // Determine latitude and longitude
    let latitude, longitude;

    if (
      selectedAddress &&
      selectedAddress.latitude !== null &&
      selectedAddress.longitude !== null
    ) {
      latitude = selectedAddress.latitude;
      longitude = selectedAddress.longitude;
    } else if (
      addresses &&
      addresses.length > 0 &&
      addresses[0].latitude !== null &&
      addresses[0].longitude !== null
    ) {
      latitude = addresses[0].latitude;
      longitude = addresses[0].longitude;
    } else {
      // Default latitude and longitude
      latitude = 9.935365;
      longitude = -84.098786;
    }

    try {
      let response;
      if (loggedInPatient) {
        response = await shoppingCartService.getCartQuotes(
          latitude,
          longitude,
          authCookie
        );
      } else {
        response = await shoppingCartService.getQuoteForCachedCart(
          itemsToQuote,
          latitude,
          longitude
        );
      }
      if (response.successful && response.value && response.value.length > 0) {
        dispatch(setQuotes(response.value));
        dispatch(setIsFetchingQuotes(false));
        return response.value;
      } else {
        // Do not reset quotes to an empty array to prevent re-fetching
        // dispatch(setQuotes([]));
        dispatch(setIsFetchingQuotes(false));
        if (!dispatch.getState().store.hasShownNoQuotesWarning) { // Added flag check
          showNoAvailableQuotesWarning();
          dispatch(setHasShownNoQuotesWarning(true)); // Set flag after showing warning
        }
        return null;
      }
    } catch (error) {
      console.error('An error occurred while fetching quotes:', error);
      // Do not reset quotes to an empty array to prevent re-fetching
      // dispatch(setQuotes([]));
      dispatch(setIsFetchingQuotes(false));
      Swal.close();
      if (!dispatch.getState().store.hasShownNoQuotesWarning) { // Added flag check
        showNoAvailableQuotesWarning();
        dispatch(setHasShownNoQuotesWarning(true)); // Set flag after showing warning
      }
      return null;
    }
  },
};

const showNoAvailableQuotesWarning = () => {
  Swal.fire({
    title: 'No hay cotizaciones disponibles',
    text: 'No hay cotizaciones disponibles para su pedido.',
    icon: 'warning',
    confirmButtonText: 'Aceptar',
    allowOutsideClick: false,
    showConfirmButton: true,
  });
};
