import { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { ShoppingCartService } from '../../../services/drsbee/data/ShoppingCartService';
import {
  changeSelectedQuote,
  resetOrderPrescriptions,
  setCacheItems,
  setCurrentOrder,
  setOrderItems,
  setQuotes,
  setPaymentMethods,
  changeSelectedPaymentMethod,
  setGuestUserData,
  setAddresses,
  changeSelectedAddress,
} from '../../../reducers/StoreSlice';
import Swal from 'sweetalert2';
import { PaymentService } from '../../../services/drsbee/data/PaymentService';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../shared/LoadingSpinner';
import CheckoutInfo from './CheckoutInfo';
import { useAuth } from '../../../context/AuthContext';
import { NativeUtilities } from '../../../utility/NativeUtilities';
import { Utilities } from '../../../utility/Utilities';
import { RequoteService } from '../../../services/drsbee/data/RequoteService';
import { useMediaQuery } from 'react-responsive';
import styled, { keyframes } from 'styled-components';
import QuoteTimer from '../cart/QuoteTimer'; // Add this import

const shoppingCartService = new ShoppingCartService();
const paymentService = new PaymentService();
const nativeUtil = new NativeUtilities();
const util = new Utilities();

const CheckoutList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authCookie } = useAuth();
  const patient = useSelector((state) => state.store.loggedInPatient);
  const selectedOrder = useSelector((state) => state.store.currentOrder);
  const selectedQuote = useSelector((state) => state.store.selectedQuote);
  const orderItems = useSelector((state) => state.store.orderItems);
  const cacheItems = useSelector((state) => state.store.cacheItems);
  const selectedPaymentMethod = useSelector(
    (state) => state.store.selectedPaymentMethod
  );
  const guestUserData = useSelector((state) => state.store.guestUserData);
  const selectedAddress = useSelector((state) => state.store.selectedAddress);
  const addresses = useSelector((state) => state.store.addresses);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [quoteExpired, setQuoteExpired] = useState(false);
  const timerIdRef = useRef(null);
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });
  const isValidCostaRicaPhoneNumber = (number) => {
    const regex = /^[245678]\d{7}$/;
    return regex.test(number);
  };
  const [isSubmitting, setIsSubmitting] = useState(false);

  const finishOrder = () => {
    dispatch(setQuotes([]));
    dispatch(changeSelectedQuote(null));
    dispatch(setCurrentOrder(null));
    dispatch(resetOrderPrescriptions());
    if (!patient) {
      dispatch(setOrderItems([]));
      dispatch(setCacheItems([]));
      dispatch(setPaymentMethods([]));
      dispatch(changeSelectedPaymentMethod(null));
      dispatch(setGuestUserData({}));
      dispatch(setAddresses([]));
      dispatch(changeSelectedAddress(null));
    } else {
      shoppingCartService.getCart(authCookie).then((response) => {
        if (response.successful && response.value !== null) {
          dispatch(setOrderItems(response.value.items));
        }
      });
    }
  };

  const validateGuestData = () => {
    const {
      patientPlaceholder,
      identificationType,
      identification,
      addressDetails,
      emailContact,
      phoneUser,
      otherSigns,
    } = guestUserData;
    let errors = [];

    // Check if patientPlaceholder is valid
    const nameParts = patientPlaceholder.trim().split(/\s+/);
    if (nameParts.length < 2 || nameParts.length > 4) {
      errors.push('El nombre completo debe tener entre 2 y 4 palabras.');
    }

    // Improved email regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(emailContact)) {
      errors.push('El formato del correo electrónico no es válido.');
    }

    // Check if phone number is valid
    if (!isValidCostaRicaPhoneNumber(phoneUser)) {
      errors.push('El número de teléfono debe ser válido de Costa Rica.');
    }

    // Check if identification is valid
    if (identification.length < 9) {
      errors.push('La identificación debe tener al menos 9 dígitos.');
    }

    // Check if address detail is valid
    if (!addressDetails || addressDetails.trim() === '') {
      errors.push('La dirección de entrega no puede estar vacía.');
    }

    // Check if other signs is provided
    if (!otherSigns || otherSigns.trim() === '') {
      errors.push('Debe proporcionar "Otras señas" para la dirección.');
    }

    return errors;
  };

  const payOrder = async () => {
    setLoading(true);

    let currentOrder = selectedOrder; // Local variable

    // Check if the quote has expired
    const quoteExpirationDateStr = currentOrder.detailsOrder.quoteExpirationDate;
    const quoteExpirationDate = new Date(quoteExpirationDateStr);
    const currentTime = new Date();

    if (currentTime >= quoteExpirationDate) {
      // Quote has expired, need to requote
      const newOrder = await handleExpiredQuote();
      if (newOrder) {
        currentOrder = newOrder; // Update currentOrder
      } else {
        setLoading(false);
        return; // Stop further processing
      }
    }

    // Proceed with payment after ensuring the quote is valid
    if (patient) {
      // Logged in user - proceed with the regular payment
      paymentService
        .payOrder(currentOrder.id, selectedPaymentMethod.id, authCookie)
        .then((response) => {
          setLoading(false);
          if (response.successful && response.value !== null && response.value.success) {
            setPaymentStatus(response.value);
            finishOrder();
            renderPaymentSuccessDialog(response.value);
          } else if (
            !response.successful &&
            response.errorMessagesStr === 'La orden ya fue pagada'
          ) {
            finishOrder();
            renderPaymentSuccessDialog(response.value);
          } else {
            renderPaymentDeclinedDialog();
          }
        });
    } else {
      // Guest user - proceed with guest payment
      const {
        patientPlaceholder,
        identificationType,
        identification,
        addressDetails,
        emailContact,
        phoneUser,
        otherSigns,
      } = guestUserData;
      const { firstName, lastName } = util.splitFullName(patientPlaceholder);

      // Combine address details with other signs
      const fullAddress = `${addressDetails}, ${otherSigns}`;

      paymentService
        .payOrderAsGuest(
          parseInt(currentOrder.id),
          selectedPaymentMethod.ccData, // Assuming ccData is available in selectedPaymentMethod
          selectedPaymentMethod.keyData, // Assuming keyData is available in selectedPaymentMethod
          identificationType,
          identification,
          firstName,
          lastName,
          fullAddress,
          emailContact,
          phoneUser.toString()
        )
        .then((response) => {
          setLoading(false);
          if (response.successful && response.value !== null && response.value.success) {
            setPaymentStatus(response.value);
            finishOrder();
            renderPaymentSuccessDialog(response.value);
          } else if (
            !response.successful &&
            response.errorMessagesStr === 'La orden ya fue pagada'
          ) {
            finishOrder();
            renderPaymentSuccessDialog(response.value);
          } else {
            renderPaymentDeclinedDialog();
          }
        });
    }
  };

  const handleExpiredQuote = async () => {
    Swal.fire({
      title: 'Cotización expirada',
      text: 'Tu cotización ha expirado, se volverá a generar la cotización para que puedas continuar.',
      icon: 'warning',
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#fdbf20',
    });

    // Requote
    const newSelectedQuote = await RequoteService.requoteOrder(
      {
        loggedInPatient: patient,
        authCookie,
        cacheItems,
        orderItems,
        selectedQuote,
        selectedAddress,
        addresses,
        dispatch,
        isRequoteTriggeredByCartChange: false,
        isRequoteTriggeredByLocationChange: false,
      },
      navigate
    );

    if (newSelectedQuote !== null) {
      // Create a new order with the new quote
      let response = await shoppingCartService.createOrderFromQuote(
        newSelectedQuote.id,
        patient && authCookie ? authCookie : null
      );

      if (response.successful && response.value !== null) {
        dispatch(setCurrentOrder(response.value));
        dispatch(changeSelectedQuote(newSelectedQuote)); // Update selectedQuote
        return response.value; // Return the new order
      } else {
        Swal.fire({
          title: 'Error',
          text: 'No se pudo crear el pedido después de actualizar la cotización.',
          icon: 'error',
          confirmButtonText: 'Aceptar',
          confirmButtonColor: '#fdbf20',
        });
        setLoading(false);
        return null; // Indicate failure
      }
    } else {
      Swal.fire({
        title: 'Error',
        text: 'No se pudo obtener una cotización válida al actualizar.',
        icon: 'error',
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#fdbf20',
      });
      setLoading(false);
      return null; // Indicate failure
    }
  };

  const renderPaymentSuccessDialog = (paymentData) => {    
    Swal.fire({
      title: 'Compra completada con éxito',
      html: `<p>Te hemos enviado un correo electrónico con más detalles. ${patient===null ? 'Te invitamos a iniciar sesión para realizar el seguimiento de tu pedido.' : ''} </p>
             <p><strong>Referencia Externa:</strong> ${paymentData.externalReference}</p>
             <p><strong>Total:</strong> ${paymentData.totalStr}</p>
             <p><strong>Descripción:</strong> ${paymentData.description}</p>
             <p><strong>Método de Pago:</strong> ${paymentData.vendorDescription} ${paymentData.maskedCreditCard}</p>`,
      icon: 'success',
      showCancelButton: patient === null,
      confirmButtonText: patient === null ? 'Seguir comprando' : 'Continuar',
      confirmButtonColor: '#401309',
      cancelButtonText: 'Iniciar sesión',
      cancelButtonColor: '#fdbf20',      
    }).then((result) => {
      if (result.isConfirmed) {
        if(patient === null) {
          navigate('/store/search');
        }else{
          navigate('/store/order/history');
        }
      } else {
        navigate('/store/login');
      }
    });
  };

  const renderPaymentDeclinedDialog = () => {
    Swal.fire({
      title: 'Ha ocurrido un error con el pago',
      text: 'No hemos podido procesar tu pago correctamente. Por favor, selecciona otro método de pago e inténtalo nuevamente.',
      icon: 'error',
      confirmButtonText: 'Revisar datos',
      confirmButtonColor: '#fdbf20',
    });
  };

  const renderConfirmationDialog = () => {
    setIsSubmitting(true);
    if (patient) {
      Swal.fire({
        title: 'Confirmar compra',
        text: 'Presione confirmar para completar su compra',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        confirmButtonColor: '#fdbf20',
        cancelButtonText: 'Cancelar',
        iconColor: '#fdbf20',
        enableEscapeKey: true,
        enableOutsideClick: true,
      }).then((result) => {
        setIsSubmitting(false);
        if (result.isConfirmed) {
          payOrder();
        }
      });
    } else {
      // Guest user - validate guest data first
      const errors = validateGuestData();
      if (errors.length > 0) {
        Swal.fire({
          title: 'Información incompleta',
          html: `<p>Por favor complete los siguientes campos:</p><ul>${errors
            .map((error) => `<li style="text-align: left">${error}</li>`)
            .join('')}</ul>`,
          icon: 'warning',
          confirmButtonText: 'Revisar datos',
          confirmButtonColor: '#fdbf20',
          enableEscapeKey: true,
          enableOutsideClick: true,          
        });
        setIsSubmitting(false);
        return;
      }

      // If validation is successful, show confirmation dialog
      Swal.fire({
        title: 'Confirmar compra',
        text: 'Presione confirmar para completar su compra',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        confirmButtonColor: '#fdbf20',
        cancelButtonText: 'Cancelar',
        iconColor: '#fdbf20',
        enableEscapeKey: true,
        enableOutsideClick: true,
      }).then((result) => {
        setIsSubmitting(false);
        if (result.isConfirmed) {
          payOrder();
        }
      });
    }
  };

  // Handle quote expiration timer
  useEffect(() => {
    if (selectedOrder && selectedOrder.detailsOrder.quoteExpirationDate) {
      const quoteExpirationDate = new Date(
        selectedOrder.detailsOrder.quoteExpirationDate
      );
      const currentTime = new Date();
      const timeRemaining = quoteExpirationDate - currentTime;

      if (timeRemaining > 0) {
        // Set timer to trigger when quote expires
        timerIdRef.current = setTimeout(async () => {
          setQuoteExpired(true);
          const newOrder = await handleExpiredQuote();
          if (newOrder) {
            // Update local order and reset quoteExpired state
            setQuoteExpired(false);
          }
        }, timeRemaining);
      } else {
        // Quote already expired
        setQuoteExpired(true);
        handleExpiredQuote();
      }
    }

    // Cleanup function to clear timeout when component unmounts or when selectedOrder changes
    return () => {
      if (timerIdRef.current) {
        clearTimeout(timerIdRef.current);
      }
    };
  }, [selectedOrder]);

  // Function to format and round amountStr
  const formatAmount = (amountStr) => {
    const numericValue = parseFloat(amountStr.replace(/[₡\s,]/g, ''));
    const roundedValue = Math.round(numericValue);
    return `₡${roundedValue.toLocaleString('en-US')}`;
  };

  const handleQuoteExpire = async () => {
    // Existing logic to handle quote expiration
    setQuoteExpired(true);
    const newOrder = await handleExpiredQuote();
    if (newOrder) {
      // Update local order and reset quoteExpired state
      setQuoteExpired(false);
    }
    // No need to dispatch setPerformRequote here
  };

  return (
    <div>
      <table className="table table-striped table-bordered overflow-x:scroll">
        <thead>
          <tr style={{ fontSize: '7.5pt', margin: '-1em', padding: '-1em' }}>
            <th
              style={{
                textAlign: 'center',
                width: '10%',
                paddingLeft: '0.1em',
                paddingRight: '0.1em',
              }}
            >
              Cantidad
            </th>
            <th style={{ textAlign: 'left', width: isDesktop? '75%' : '62%' }}>Descripcion</th>
            <th style={{ textAlign: 'right' }}>Precio</th>
          </tr>
        </thead>
        <tbody>
          {selectedOrder !== null && selectedOrder.details.length > 0
            ? selectedOrder.details.map((item, index) => (
                <tr
                  key={index}
                  style={{ fontSize: '8.5pt', position: 'relative' }}
                >
                  <td style={{ textAlign: 'center' }}>{item.quantity}</td>
                  <td style={{ textAlign: 'left' }}>{item.description}</td>
                  <td style={{ textAlign: 'right', backgroundColor: '#7acdcb' }}>
                    <b>{formatAmount(item.amountStr)}</b>
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
      <hr />
      <CheckoutInfo />
      <hr />
      {selectedOrder !== null && selectedQuote !== null && (
        <table className="table table-striped table-bordered overflow-x:scroll">
          <tbody>
            <tr>
              <td>Subtotal</td>
              <td style={{ textAlign: 'right' }}>
                ₡{selectedQuote.subTotalWithoutTax.toFixed(2)}
              </td>
            </tr>
            <tr>
              <td>Impuestos</td>
              <td style={{ textAlign: 'right' }}>{selectedQuote.totalTaxStr}</td>
            </tr>
            {selectedOrder.discountTotal > 0 && (
              <tr>
                <td>Descuento</td>
                <td style={{ textAlign: 'right' }}>
                  {selectedOrder.discountTotalStr}
                </td>
              </tr>
            )}
            <tr>
              <td>
                <h4>Total</h4>
              </td>
              <td style={{ textAlign: 'right' }}>
                <h4 style={{ color: '#FF0000' }}>{selectedOrder.grandTotalStr}</h4>
              </td>
            </tr>
          </tbody>
        </table>
      )}
      {loading ? (
        <div className="d-flex flex-row justify-content-center m-5">
          <LoadingSpinner />
          <h5 style={{ color: '#401409' }}>
            Procesando pago, por favor espere...
          </h5>
        </div>
      ) : null}
      {!loading && (
        <div
          style={{ width: '100%', display: 'flex', alignItems: 'center' }}
          className="d-flex flex-row justify-content-center"
        >
          <QuoteTimer
            expirationDate={selectedOrder?.detailsOrder?.quoteExpirationDate}
            onExpire={handleQuoteExpire}
          />
          <Button
            style={{
              borderRadius: '0.9em',
              backgroundColor: '#fdbf20',
              color: '#401409',
              border: '0.1em solid #ffffff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              alignContent: 'center',
            }}
            variant="warning"
            onClick={renderConfirmationDialog}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <>
                <Spinner />
                Iniciando pago...
              </>
            ) : (
              'Pagar pedido'
            )}
          </Button>
        </div>
      )}
    </div>
  );
};

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #e0a800;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: ${spin} 2s linear infinite;
  margin-right: 10px;

  @media (max-width: 480px) {
    width: 16px;
    height: 16px;
    border-width: 3px;
  }
`;

export default CheckoutList;
