import { configureStore } from '@reduxjs/toolkit';
import rxReducer from '../reducers/RxSlice';
import storeReducer from '../reducers/StoreSlice';
import tempReducer from '../reducers/TempSlice';

const store = configureStore({
  reducer: {
    prescription: rxReducer,
    store: storeReducer,
    temp: tempReducer,
  },
});

export default store;