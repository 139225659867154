import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setGuestUserData, setIsComingFromCheckout } from '../../../reducers/StoreSlice';
import { useNavigate } from 'react-router-dom';

import { FaSyncAlt } from 'react-icons/fa';
const CheckoutInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const patient = useSelector((state) => state.store.loggedInPatient);
  const selectedAddress = useSelector((state) => state.store.selectedAddress);
  const selectedPaymentMethod = useSelector(
    (state) => state.store.selectedPaymentMethod
  );
  const selectedQuote = useSelector((state) => state.store.selectedQuote);
  const guestUserData = useSelector((state) => state.store.guestUserData);
  const [patientPlaceholder, setPatientPlaceholder] = useState(
    patient === null &&
      selectedPaymentMethod !== null &&
      selectedPaymentMethod.cardHolderName !== null
      ? selectedPaymentMethod.cardHolderName
      : patient
        ? patient.identification +
        ' - ' +
        patient.firstName +
        ' ' +
        patient.lastName
        : guestUserData?.patientPlaceholder || ''
  );
  const [identificationType, setIdentificationType] = useState(
    guestUserData?.identificationType || '1'
  );
  const [identification, setIdentification] = useState(
    guestUserData?.identification || ''
  );
  const [emailContact, setEmailContact] = useState(
    guestUserData?.emailContact || ''
  );
  const [phoneUser, setPhoneUser] = useState(
    selectedAddress
      ? selectedAddress.phone
        ? selectedAddress.phone
        : guestUserData?.phoneUser || ''
      : guestUserData?.phoneUser || ''
  );
  const [addressDetails, setAddressDetails] = useState(
    selectedAddress
      ? selectedAddress.addressDetail
      : guestUserData?.addressDetails || ''
  );
  const [otherSigns, setOtherSigns] = useState(guestUserData?.otherSigns || '');
  const changePaymentMethod = () => {
    dispatch(setIsComingFromCheckout(true));
    if (patient === null) {
      navigate('/store/cart/payment_methods/pay');
    } else {
      navigate('/store/cart/payment_methods');
    }
  };
  useEffect(() => {
    const guestUserData = {
      patientPlaceholder,
      identificationType,
      identification,
      emailContact,
      phoneUser,
      addressDetails,
      otherSigns,
    };
    dispatch(setGuestUserData(guestUserData));
  }, [
    patientPlaceholder,
    identificationType,
    identification,
    emailContact,
    phoneUser,
    addressDetails,
    otherSigns,
    dispatch,
  ]);

  return (
    <>
      <h5>Detalle de compra</h5>
      <table
        className="table table-striped table-bordered"
        style={{ fontSize: '9pt' }}
      >
        <tbody>
          <tr>
            <td>Paciente:</td>
            <td>
              {patient !== null ? (
                <span>{patientPlaceholder}</span>
              ) : (
                <>
                  <input
                    type="text"
                    className="form-control mb-2"
                    value={patientPlaceholder}
                    placeholder="Nombre completo"
                    onChange={(e) => setPatientPlaceholder(e.target.value)}
                  />
                  <div className="form-group mb-2">
                    <label>Tipo de Identificación</label>
                    <select
                      className="form-control"
                      value={identificationType}
                      onChange={(e) => setIdentificationType(e.target.value)}
                    >
                      <option value="1">Cédula Nacional</option>
                      <option value="2">DIMEX</option>
                    </select>
                  </div>
                  <div className="form-group mb-2">
                    <label>Número de Identificación</label>
                    <input
                      type="text"
                      className="form-control"
                      value={identification}
                      placeholder="Número de identificación"
                      onChange={(e) => setIdentification(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-2">
                    <label>Correo Electrónico</label>
                    <input
                      type="email"
                      className="form-control"
                      value={emailContact}
                      placeholder="Correo electrónico"
                      onChange={(e) => setEmailContact(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-2">
                    <label>Número de Teléfono</label>
                    <input
                      type="tel"
                      className="form-control"
                      value={phoneUser}
                      placeholder="Número de teléfono"
                      onChange={(e) => setPhoneUser(e.target.value)}
                    />
                  </div>
                </>
              )}
            </td>
          </tr>
          <tr>
            <td>Dirección de entrega:</td>
            <td>
              {selectedQuote !== null && selectedQuote.pickUpAtPharmacy ? (
                'Recoger en farmacia'
              ) : (
                <>
                  {patient !== null ? (
                    <span>{addressDetails}</span>
                  ) : (
                    <>
                      <span style={{fontWeight: 'bold'}}>{addressDetails}</span>
                      <div className="form-group mt-2">
                        <label>Otras señas</label>
                        <input
                          type="text"
                          className="form-control"
                          value={otherSigns}
                          placeholder="Otras señas"
                          onChange={(e) => setOtherSigns(e.target.value)}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </td>
          </tr>
          <tr onClick={() => changePaymentMethod()}>
            <td>Método de pago:</td>
            <td style={{ fontSize: patient !== null ? '9pt' : '11pt', textDecoration: 'underline', color: '#401409' }}>
              <FaSyncAlt style={{ color: '#fdbf20', marginLeft: '0.3em', marginRight: '0.3em', fontSize: '13pt' }} />
              {patient !== null
                ? selectedPaymentMethod.vendorDescription +
                ' - ' +
                selectedPaymentMethod.maskedNumber
                : selectedPaymentMethod !== null ? selectedPaymentMethod.maskedNumber : ''}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default CheckoutInfo;
