import React, { useState, useEffect } from 'react';
import { Form, Button, Card } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
  addAddress,
  changeSelectedAddress,
  setRedirectToQuotes,
  setPerformRequote,
  setRefreshQuotes
} from '../../../reducers/StoreSlice';
import { useNavigate, Link } from 'react-router-dom';
import MapPicker from 'react-google-map-picker';
import { LocationService } from '../../../services/drsbee/data/LocationService';
import { AddressService } from '../../../services/drsbee/data/AddressService';
import LoadingSpinner from '../../shared/LoadingSpinner';
import { useAuth } from '../../../context/AuthContext';
import { NativeUtilities } from '../../../utility/NativeUtilities';
import { useMediaQuery } from 'react-responsive';
const DefaultZoom = 17;

const AddAddressForm = ({ onAddAddress }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [newAddress, setNewAddress] = useState('');
  const [reference, setReference] = useState('');
  const [abbAddress, setAbbAddress] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [location, setLocation] = useState(null);
  const [zoom, setZoom] = useState(DefaultZoom);
  const [loadMap, setLoadMap] = useState(false);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const authCookie = useAuth();
  const addresses = useSelector((state) => state.store.addresses);
  const loggedInPatient = useSelector((state) => state.store.loggedInPatient);
  const redirectToQuotes = useSelector((state) => state.store.redirectToQuotes);
  const selectedQuote = useSelector((state) => state.store.selectedQuote);
  const locationService = new LocationService();
  const addressService = new AddressService();
  const nativeUtil = new NativeUtilities();
  const quotesRoute =
    loggedInPatient !== null ? '/store/cart/quotes' : '/store/cart/cache/quotes';

  const goBackRoute = loggedInPatient !== null ? '/store/cart/addresses' : '/store/cart';
  const isAuthenticated = loggedInPatient !== null;
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });

  const handleNewAddressChange = (event) => {
    setNewAddress(event.target.value);
  };

  const handleReferenceChange = (event) => {
    setReference(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const isValidCostaRicaPhoneNumber = (number) => {
    const regex = /^[245678]\d{7}$/;
    return regex.test(number);
  };

  const isFormValid = () => {
    if (!abbAddress) return false;
    if (isAuthenticated) {
      if (!newAddress.trim()) return false;
      if (!reference.trim()) return false;
      if (!isValidCostaRicaPhoneNumber(phoneNumber)) return false;
    }
    return true;
  };

  const moveForward = () => {
    if (redirectToQuotes) {
      dispatch(setRedirectToQuotes(false));
      navigate(quotesRoute);
    } else {
      navigate('/store/cart');
    }
  };

  const handleAddAddress = async () => {
    if (isAuthenticated && (!isValidCostaRicaPhoneNumber(phoneNumber) || !newAddress.trim() || !reference.trim())) {
      // Safeguard in case button is somehow enabled
      return;
    }

    const addressDetail = abbAddress ? abbAddress.display_name : 'Opcional';
    const deliveryPlaceDetail = isAuthenticated
      ? reference + ' - ' + newAddress
      : 'Dirección sin detalles';

    const newAddressObject = {
      id: addresses.length + 1,
      longitude: location ? location.lng : null,
      latitude: location ? location.lat : null,
      addressDetail: addressDetail,
      deliveryPlaceDetail: deliveryPlaceDetail,
      politicalRegion: null,
      phone: isAuthenticated ? phoneNumber : null,
    };

    try {
      if (isAuthenticated) {
        const response = await addressService.addAddress(
          newAddressObject,
          authCookie
        );
        if (response.successful && response.value !== null) {
          dispatch(addAddress(response.value));
          dispatch(changeSelectedAddress(response.value));
          window.localStorage.setItem('selectedAddress', JSON.stringify(response.value));                  
          dispatch(setRefreshQuotes(true));          
          moveForward();
        }
      } else {
        dispatch(addAddress(newAddressObject));
        dispatch(changeSelectedAddress(newAddressObject));
        window.localStorage.setItem(
          'selectedAddress',
          JSON.stringify(newAddressObject)
        );
        
        dispatch(setRefreshQuotes(true));
        
        moveForward();
      }
    } catch (error) {
      console.error('Error adding address:', error);
    }
  };

  const handleChangeLocation = (lat, lng) => {
    setLocation({ lat: lat, lng: lng });
    locationService.getAbbreviatedAddress(lat, lng).then((data) => {
      setAbbAddress(data);
    });
  };

  const handleChangeZoom = (newZoom) => {
    setZoom(newZoom);
  };

  const handleResetLocation = () => {
    setLocation(null);
    setZoom(DefaultZoom);
  };
  useEffect(() => {
    // First try to get the location using geolocation API
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          locationService.getAbbreviatedAddress(latitude, longitude).then((data) => {
            setAbbAddress(data);
            setLocation({ lat: latitude, lng: longitude });
            setLoadMap(true);
          });
        },
        () => {
          // Fallback to IP-based location if geolocation fails
          nativeUtil.getIPLocation((data) => {
            const latitude = parseFloat(data.lat) || 9.934668069261619;
            const longitude = parseFloat(data.lon) || -84.08104447952564;
            locationService.getAbbreviatedAddress(latitude, longitude).then((data) => {
              setAbbAddress(data);
              setLocation({ lat: latitude, lng: longitude });
              setLoadMap(true);
            });
          });
        }
      );
    } else {
      // Fallback to IP-based location if geolocation is not supported
      nativeUtil.getIPLocation((data) => {
        const latitude = parseFloat(data.lat) || 9.934668069261619;
        const longitude = parseFloat(data.lon) || -84.08104447952564;
        locationService.getAbbreviatedAddress(latitude, longitude).then((data) => {
          setAbbAddress(data);
          setLocation({ lat: latitude, lng: longitude });
          setLoadMap(true);
        });
      });
    }
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAw9vDckYlDxBBl5wnMGFu11StyXAqepO0&libraries=places`;
    script.async = true;
    script.defer = true;
    script.onload = () => {
      setScriptLoaded(true);
    };
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <>
      <Card>
        <Card.Header>
          <h2 style={{ textAlign: 'center' }}>
            {isAuthenticated ? 'Agregar una nueva dirección' : 'Confirmar la ubicación'} de entrega
          </h2>
        </Card.Header>
        <Card.Body>
          {!loadMap ? (
            <div className="d-flex flex-row justify-content-center m-5">
              <LoadingSpinner />
              <h5>Cargando mapa...</h5>
            </div>
          ) : (
            <MapPicker
              className="m-1"
              defaultLocation={location}
              zoom={zoom}
              mapTypeId="roadmap"
              style={{ height: isDesktop ? '40em': '35em', width: '98%' }}
              onChangeLocation={handleChangeLocation}
              onChangeZoom={handleChangeZoom}
              apiKey="AIzaSyAw9vDckYlDxBBl5wnMGFu11StyXAqepO0"
            />
          )}
          {abbAddress !== null && (
            <p style={{ color: '#309063', margin: '1em' }}>
              <b>{abbAddress.display_name}</b>
            </p>
          )}
          {isAuthenticated && (
            <>
              <Form.Group className="mt-3">
                <Form.Label>
                  Otras señas<span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingrese otras señas"
                  value={newAddress}
                  onChange={handleNewAddressChange}
                  isInvalid={isAuthenticated && !newAddress.trim()}
                />
                <Form.Control.Feedback type="invalid">
                  Este campo es obligatorio.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mt-3">
                <Form.Label>
                  Referencia (Domicilio / Trabajo / Etc)
                  <span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingrese una referencia"
                  value={reference}
                  onChange={handleReferenceChange}
                  isInvalid={isAuthenticated && !reference.trim()}
                />
                <Form.Control.Feedback type="invalid">
                  Este campo es obligatorio.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mt-3">
                <Form.Label>
                  Número de teléfono<span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Ingrese su número de teléfono"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  isInvalid={isAuthenticated && !isValidCostaRicaPhoneNumber(phoneNumber)}
                />
                <Form.Control.Feedback type="invalid">
                  Ingrese un número de teléfono válido de Costa Rica.
                </Form.Control.Feedback>
              </Form.Group>
            </>
          )}
        </Card.Body>
        <Card.Footer className="d-flex flex-row justify-content-between">
          <Link to={goBackRoute}>
            <Button style={{backgroundColor: '#bdcad5', color: '#401409'}} className="">
              Cancelar
            </Button>
          </Link>
          <Button
            style={{backgroundColor: '#fdbf20', color: '#401409'}}
            onClick={handleAddAddress}
            disabled={!isFormValid()}
            className=""
          >
            {isAuthenticated ? 'Agregar' : 'Confirmar'}
          </Button>
        </Card.Footer>
      </Card>
    </>
  );
};

export default AddAddressForm;
