// ShoppingCartList.js

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaWindowClose } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import { ShoppingCartService } from '../../../services/drsbee/data/ShoppingCartService';
import {
  changeSelectedQuote,
  resetOrderPrescriptions,
  setCacheItems,
  setOrderItems,
  setQuotes,
  removePrescriptionFromOrder,
  updatePrescriptionItemQuantity,
} from '../../../reducers/StoreSlice';
import Swal from 'sweetalert2';
import { useAuth } from '../../../context/AuthContext';
import { useMediaQuery } from 'react-responsive';
import { RequoteService } from '../../../services/drsbee/data/RequoteService';
import { useNavigate } from 'react-router-dom';

const shoppingCartService = new ShoppingCartService();

const ShoppingCartList = () => {
  const dispatch = useDispatch();
  const { authCookie } = useAuth();
  const loggedInPatient = useSelector((state) => state.store.loggedInPatient);
  const items = useSelector((state) => state.store.orderItems);
  const cacheItems = useSelector((state) => state.store.cacheItems);
  const addresses = useSelector((state) => state.store.addresses);
  const currentItems = loggedInPatient !== null ? items : cacheItems;
  const selectedQuote = useSelector((state) => state.store.selectedQuote);
  const selectedAddress = useSelector((state) => state.store.selectedAddress);
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });
  const navigate = useNavigate();

  // Function to get item ID for cart operations
  const getItemIdForCartOperations = (item) => {
    return loggedInPatient !== null ? item.id : item.relatedEntityId.toString();
  };

  // Function to get item ID for price matching
  const getItemIdForPriceMatching = (item) => {
    return item.relatedEntityId.toString();
  };

  const getItemPrice = (id) => {
    if (selectedQuote !== null) {
      const matchingItems = selectedQuote.cartQuoteItems.filter(
        (item) => item.relatedEntityId.toString() === id
      );
      if (matchingItems.length > 0) {
        const totalPrice = matchingItems.reduce((sum, item) => {
          const priceNumber = parseFloat(item.totalPriceStr.replace(/[₡,\s]/g, ''));
          return sum + priceNumber;
        }, 0);

        const roundedPrice = Math.round(totalPrice);
        return `₡${roundedPrice.toLocaleString('en-US')}`;
      }
    }
    return null;
  };

  const getExpressPrice = () => {
    if (selectedQuote !== null && selectedQuote.express) {
      const priceStr = selectedQuote.expressFinalPriceStr.replace(/[₡,\s]/g, '');
      const priceNumber = parseFloat(priceStr);
      const roundedPrice = Math.round(priceNumber);
      return `₡${roundedPrice.toLocaleString('en-US')}`;
    }
    return null;
  };

  const getItemPriceColorCode = (item) => {
    if (getItemPrice(getItemIdForPriceMatching(item)) !== null) {
      const matchingItems = selectedQuote.cartQuoteItems.filter(
        (quoteItem) =>
          quoteItem.relatedEntityId.toString() === getItemIdForPriceMatching(item)
      );
      if (matchingItems.length > 0) {
        const totalRequestedDose = matchingItems[0].requestedDose;
        const totalDose = matchingItems.reduce((sum, quoteItem) => {
          return sum + quoteItem.totalDose;
        }, 0);
        if (totalDose >= totalRequestedDose) {
          return '#7acdcb';
        }
      }
    }
    return '#fbba5a';
  };

  const getItemDisplayQuantity = (item) => {
    if (selectedQuote !== null) {
      const matchingItems = selectedQuote.cartQuoteItems.filter(
        (quoteItem) =>
          quoteItem.relatedEntityId.toString() === getItemIdForPriceMatching(item)
      );
      if (matchingItems.length > 0) {
        return matchingItems[0].quantity;
      }
    }
    return item.quantity;
  };

  const getItemDisplayDescription = (item) => {
    if (selectedQuote !== null) {
      const matchingItems = selectedQuote.cartQuoteItems.filter(
        (quoteItem) =>
          quoteItem.relatedEntityId.toString() === getItemIdForPriceMatching(item)
      );
      if (matchingItems.length > 0) {
        let totalQuotedDose = matchingItems.reduce((sum, quoteItem) => {
          return sum + quoteItem.totalDose;
        }, 0);
        let displayDescription = matchingItems[0].presentationDescription;
        if (matchingItems.length > 1) {
          matchingItems
            .filter((quoteItem) => quoteItem !== matchingItems[0])
            .forEach((quoteItem) => {
              displayDescription += ` + ${quoteItem.totalDose} ${quoteItem.presentationItemDescription} individuales`;
            });
        }
        if (totalQuotedDose < matchingItems[0].requestedDose) {
          let availabilityPercentage =
            (totalQuotedDose / matchingItems[0].requestedDose) * 100;
          displayDescription += ` - Disponible ${availabilityPercentage.toFixed(
            2
          )}% de la cantidad solicitada (${totalQuotedDose}/${
            matchingItems[0].requestedDose
          } ${matchingItems[0].doseUnitDescription.toLowerCase()})`;
        }
        return displayDescription;
      }
    }
    return item.description;
  };

  const getItemDoseUnit = (item) => {
    if (selectedQuote !== null) {
      const matchingItems = selectedQuote.cartQuoteItems.filter(
        (quoteItem) =>
          quoteItem.relatedEntityId.toString() === getItemIdForPriceMatching(item)
      );
      if (matchingItems.length > 0) {
        return matchingItems[0].doseUnitDescription;
      }
    }
    return '';
  };

  const getItemPresentationItemDescription = (item) => {
    if (selectedQuote !== null) {
      const matchingItems = selectedQuote.cartQuoteItems.filter(
        (quoteItem) =>
          quoteItem.relatedEntityId.toString() === getItemIdForPriceMatching(item)
      );
      if (matchingItems.length > 0) {
        return matchingItems[0].presentationItemDescription;
      }
    }
    return item.quantity > 1
      ? item.doseUnitDescriptionPlural
      : item.doseUnitDescriptionSingular;
  };

  const getDoseUnitDescription = (item) => {
    const quantity = parseInt(item.quantity, 10);
    if (quantity > 1) {
      return item.doseUnitDescriptionPlural || '';
    } else {
      return item.doseUnitDescriptionSingular || '';
    }
  };

  const handleLongPress = (item) => {
    if (item.prescriptionId === null) {
      const id = getItemIdForPriceMatching(item); // Use price matching ID for editing
      const currentQuantity =
        currentItems.find((i) => getItemIdForPriceMatching(i) === id)?.quantity || '';
      const doseUnit = getDoseUnitDescription(item);

      Swal.fire({
        title: 'Modificar Cantidad',
        html: `
          <div style="display: flex; align-items: center; justify-content: center;">
            <input type="number" id="quantity-input" min="1" value="${currentQuantity}" style="width: 4em; margin-right: 1em;">
            <label>${doseUnit}</label>
          </div>
        `,
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        confirmButtonColor: '#fdbf20',
        confirmButtonTextColor: '#401409',
        cancelButtonText: 'Cancelar',
        preConfirm: () => {
          const inputValue = Swal.getPopup().querySelector('#quantity-input').value;
          if (!inputValue || inputValue <= 0) {
            Swal.showValidationMessage('Por favor ingrese una cantidad válida.');
            return false;
          }
          return inputValue;
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const newQuantity = parseInt(result.value, 10);
          handleQuantityChange(id, newQuantity);
        }
      });
    }
  };

  const clearQuotes = () => {
    dispatch(changeSelectedQuote(null));
    dispatch(setQuotes([]));
  };

  const showNoAvailableQuotesWarning = () => {
    Swal.fire({
      title: 'No hay cotizaciones disponibles',
      text: 'No hay cotizaciones disponibles para su pedido.',
      icon: 'warning',
      confirmButtonText: 'Aceptar',
      allowOutsideClick: false,
      showConfirmButton: true,
    });
  };

  const handleQuantityChange = async (id, newQuantity) => {
    const itemId = id;
    if (loggedInPatient !== null) {
      // Logged-in user
      try {
        const response = await shoppingCartService.updateCartItem(
          itemId,
          newQuantity,
          authCookie
        );
        if (response.successful && response.value !== null) {
          dispatch(
            setOrderItems(response.value.items.filter((item) => item.status === '1'))
          );
          dispatch(changeSelectedQuote(null)); // Reset selectedQuote to update UI

          await RequoteService.requoteOrder(
            {
              loggedInPatient,
              authCookie,
              cacheItems,
              orderItems: response.value.items.filter((item) => item.status === '1'),
              selectedQuote,
              selectedAddress,
              addresses,
              dispatch,
              isRequoteTriggeredByCartChange: true,
              isRequoteTriggeredByLocationChange: false,
            },
            navigate
          );
        } else {
          console.error('Failed to update cart item');
        }
      } catch (error) {
        console.error('An error occurred while updating the cart item:', error);
      }
    } else {
      // Guest user
      const updatedCacheItems = cacheItems.map((item) =>
        getItemIdForCartOperations(item) === itemId
          ? { ...item, quantity: newQuantity }
          : item
      );
      dispatch(setCacheItems(updatedCacheItems));
      dispatch(setOrderItems(updatedCacheItems));
      dispatch(changeSelectedQuote(null)); // Reset selectedQuote to update UI

      await RequoteService.requoteOrder(
        {
          loggedInPatient,
          authCookie,
          cacheItems: updatedCacheItems,
          orderItems: updatedCacheItems,
          selectedQuote,
          selectedAddress,
          addresses,
          dispatch,
          isRequoteTriggeredByCartChange: true,
          isRequoteTriggeredByLocationChange: false,
        },
        navigate
      );
    }
  };

  const handleConfirmDelete = async (id) => {
    if (loggedInPatient === null) {
      // Guest user
      const updatedCacheItems = cacheItems.filter(
        (item) => getItemIdForCartOperations(item) !== id
      );
      dispatch(setCacheItems(updatedCacheItems));
      dispatch(setOrderItems(updatedCacheItems));
      await updateAfterDelete(updatedCacheItems, id);
    } else {
      // Logged-in user
      try {
        const response = await shoppingCartService.removeFromCart(id, authCookie);
        if (response.successful && response.value !== null) {
          const remainingItems = response.value.items.filter(
            (item) => item.status === '1'
          );
          dispatch(setOrderItems(remainingItems));
          await updateAfterDelete(remainingItems, id);
        }
      } catch (error) {
        console.error('An error occurred while removing the cart item:', error);
      }
    }
  };

  const handleCancelDelete = () => {};

  const updateAfterDelete = async (remainingItems, deletedItemId) => {
    if (remainingItems.length === 0) {
      clearQuotes();
      dispatch(resetOrderPrescriptions());
    } else if (selectedQuote !== null) {
      // There is a selectedQuote and at least one item remaining
      await RequoteService.requoteOrder(
        {
          loggedInPatient,
          authCookie,
          cacheItems: loggedInPatient ? null : remainingItems,
          orderItems: loggedInPatient ? remainingItems : null,
          selectedQuote,
          selectedAddress,
          addresses,
          dispatch,
          isRequoteTriggeredByCartChange: true,
          isRequoteTriggeredByLocationChange: false,
        },
        navigate
      );
    }
    // Else, do not do anything (no need to change selectedQuote or requote)

    // Check if the deleted item had a prescriptionId
    const deletedItem = currentItems.find(
      (item) => getItemIdForCartOperations(item) === deletedItemId
    );
    if (deletedItem && deletedItem.prescriptionId) {
      // Check for other items with the same prescriptionId
      const itemsWithSamePrescription = remainingItems.filter(
        (item) =>
          item.prescriptionId !== null && item.prescriptionId.toString() === deletedItem.prescriptionId.toString()
      );
      if (itemsWithSamePrescription.length === 0) {
        dispatch(removePrescriptionFromOrder(deletedItem.prescriptionId.toString()));
      } else {
        dispatch(
          updatePrescriptionItemQuantity(
            deletedItem.prescriptionId.toString(),
            itemsWithSamePrescription.length
          )
        );
      }
    }
  };

  const renderDeleteConfirmationDialog = (id) => {
    Swal.fire({
      title: 'Eliminar item',
      text: 'Está seguro de que desea eliminar el item de la lista?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      confirmButtonColor: '#FF0000',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        handleConfirmDelete(id);
      } else {
        handleCancelDelete();
      }
    });
  };

  const handleTouchStart = (itemId) => (e) => {
    const touch = e.touches[0];
    const startX = touch.clientX;
    const startY = touch.clientY;
    let hasSwiped = false;

    const onTouchMove = (e) => {
      const touch = e.touches[0];
      const deltaX = touch.clientX - startX;
      const deltaY = touch.clientY - startY;
      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        hasSwiped = true;
      }
    };

    const onTouchEnd = () => {
      if (hasSwiped) {
        renderDeleteConfirmationDialog(itemId);
      }
      document.removeEventListener('touchmove', onTouchMove);
      document.removeEventListener('touchend', onTouchEnd);
    };
    document.addEventListener('touchmove', onTouchMove);
    document.addEventListener('touchend', onTouchEnd);
  };

  const getEstimateExpressDateLabel = (estimateExpressDate) => {
    if (!estimateExpressDate) {
        return <span>Tiempo de entrega estimado no disponible</span>;
    }
    try {
        const now = new Date();
        const estDate = new Date(estimateExpressDate);
        const isSameDay = now.toDateString() === estDate.toDateString();
        
        // Calculate tomorrow's date
        const tomorrow = new Date(now);
        tomorrow.setDate(now.getDate() + 1);
        const isTomorrow = estDate.toDateString() === tomorrow.toDateString();

        // Function to format time in am/pm
        const formatAMPM = (date) => {
            let hours = date.getHours();
            let minutes = date.getMinutes();
            const ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0'+minutes : minutes;
            const strTime = `${hours}:${minutes} ${ampm}`;
            return strTime;
        };

        const estTime = formatAMPM(estDate);
        const estDay = estDate.getDate().toString().padStart(2, '0');
        const estMonth = (estDate.getMonth() + 1).toString().padStart(2, '0');
        const estDateStr = `${estDay}/${estMonth}/${estDate.getFullYear()}`;

        if (isSameDay) {
            const diffMs = estDate - now;
            const diffHours = Math.round(diffMs / 3600000);
            return (
                <span>
                    Tiempo de entrega estimado: <span style={{color: '#59b571'}}><b>~{diffHours} hora{diffHours !== 1 ? 's' : ''}</b></span>. 
                </span>
            );
        } else if (isTomorrow) {
            return (
                <span>
                    Entrega mañana: <span style={{color: '#ff8d00'}}><b>~{estTime}</b></span>
                </span>
            );
        } else {
            return (
                <span>
                    Fecha de entrega estimada: <span style={{color: '#ff8d00'}}><b>{estDateStr}</b> ~<b>{estTime}</b></span>
                </span>
            );
        }
    } catch {
        return <span>Tiempo de entrega estimado no disponible</span>;
    }
};

  return (
    <div style={{ height: '50vh', overflowY: 'scroll' }}>
      <table className="table table-striped table-bordered">
        <thead>
          <tr style={{ fontSize: '8.5pt', margin: '-1em', padding: '-1em' }}>
            <th style={{ textAlign: 'center', width: '4em' }}>Cantidad</th>
            <th style={{ textAlign: 'left', width: '10%' }}>
              {selectedQuote === null ? 'Unidad' : 'Presentacion'}
            </th>
            <th style={{ textAlign: 'left', width: '60%' }}>Descripcion</th>
            <th
              style={{
                textAlign: 'right',
                display: selectedQuote === null ? 'none' : 'table-cell',
              }}
            >
              Precio
            </th>
            {isDesktop && <th style={{ width: '5em' }}></th>}
          </tr>
        </thead>
        <tbody>
          {currentItems.length > 0
            ? currentItems.map((item, index) => (
                <tr
                  key={getItemIdForPriceMatching(item) || index}
                  style={{ fontSize: '8.5pt', position: 'relative' }}
                  onTouchStart={
                    !isDesktop
                      ? handleTouchStart(getItemIdForCartOperations(item))
                      : null
                  }
                >
                  <td
                    style={{
                      textAlign: 'center',
                      marginLeft: 'em',
                      alignContent: 'center',
                    }}
                    onClick={() => handleLongPress(item)}
                  >
                    <span>{getItemDisplayQuantity(item)}</span>
                  </td>
                  <td style={{ textAlign: 'left', alignContent: 'center' }}>
                    {selectedQuote === null
                      ? getDoseUnitDescription(item)
                      : getItemPresentationItemDescription(item)}
                  </td>
                  <td style={{ textAlign: 'left', alignContent: 'center' }}>
                    {getItemDisplayDescription(item)}
                  </td>
                  <td
                    style={{
                      textAlign: 'right',
                      alignContent: 'center',
                      display: selectedQuote === null ? 'none' : 'table-cell',
                      backgroundColor: getItemPriceColorCode(item),
                    }}
                  >
                    <b>
                      {getItemPrice(getItemIdForPriceMatching(item)) !== null
                        ? getItemPrice(getItemIdForPriceMatching(item))
                        : 'No Disponible'}
                    </b>
                  </td>
                  {isDesktop && (
                    <td
                      style={{ textAlign: 'center', alignContent: 'center' }}
                      onClick={() =>
                        renderDeleteConfirmationDialog(
                          getItemIdForCartOperations(item)
                        )
                      }
                    >
                      <FaWindowClose
                        style={{ color: 'red', width: '2em', height: '2em' }}
                      />
                    </td>
                  )}
                </tr>
              ))
            : null}
          {selectedQuote !== null && selectedQuote.totalDiscount > 0 && (
            <tr>
              <td style={{ textAlign: 'center', fontSize: '8.5pt', alignContent: 'center'  }}>1</td>
              <td style={{ textAlign: 'left', fontSize: '8.5pt', alignContent: 'center'  }}></td>
              <td style={{ textAlign: 'left', fontSize: '8.5pt', alignContent: 'center'  }}>Descuento</td>
              <td
                style={{
                  textAlign: 'right',
                  alignContent: 'center',
                  fontSize: '8.5pt',
                  backgroundColor: '#7acdcb',
                }}
              >
                <b>{`-₡${selectedQuote.totalDiscount.toLocaleString('en-US')}`}</b>
              </td>
              {isDesktop && <td></td>}
            </tr>
          )}
          {selectedQuote !== null && selectedQuote.express && (
            <tr>
              <td style={{ textAlign: 'center', fontSize: '8.5pt', alignContent: 'center' }}>1</td>
              <td style={{ textAlign: 'left', fontSize: '8.5pt', alignContent: 'center'  }}></td>
              <td style={{ textAlign: 'left', fontSize: '8.5pt', alignContent: 'center'  }}>
                Express | {getEstimateExpressDateLabel(selectedQuote.estimateExpressDate)}
              </td>
              <td
                style={{
                  textAlign: 'right',
                  alignContent: 'center', 
                  fontSize: '8.5pt',
                  backgroundColor: '#7acdcb',
                }}
              >
                <b>{getExpressPrice()}</b>
              </td>
              {isDesktop && <td></td>}
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ShoppingCartList;
