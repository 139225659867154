import React from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { changeSelectedQuote, setPerformRequote } from '../../../reducers/StoreSlice';
import { useNavigate } from "react-router-dom";
import { FaMapMarkerAlt } from 'react-icons/fa';
import { MdLocalPharmacy, MdAccessTimeFilled } from 'react-icons/md';

const OrderQuote = ({ quote, onClick }) => {
    const dispatch = useDispatch();
    const selectedQuote = useSelector((state) => state.store.selectedQuote);
    const navigate = useNavigate();
    const colorCode = quote.quotePresentationAvailabilityPercentage === 100 ? '#7acdcb' : '#fbba5a';
    const handleSelectQuote = () => {
        dispatch(changeSelectedQuote(quote));
        dispatch(setPerformRequote(false));
        navigate('/store/cart/');
    };

    const isSelected = selectedQuote && quote.pharmacyId === selectedQuote.pharmacyId;

    // Format the price as done in the current code
    const formatRoundedPrice = (priceStr) => {
        let numericStr = priceStr.replace(/[₡,\s]/g, '');
        let priceNum = parseFloat(numericStr);
        let roundedPrice = Math.round(priceNum);
        let formattedPrice = roundedPrice.toLocaleString('en-US');
        return `₡${formattedPrice}`;
    };

    const formattedPrice = formatRoundedPrice(quote.totalPriceStr);

    // Use the same background color logic from the current (new) code
    const backgroundColor =
        quote.quotePresentationAvailabilityPercentage === 100
            ? '#7acdcb' // Light green for complete quotes
            : '#fbba5a'; // Light orange for partial quotes

    // Maintain the top border line style from the previous (old) code
    // The top border line color comes from the colorCode prop

    const icon = quote.express ? (
        <FaMapMarkerAlt style={{ fontSize: '15px', color: '#401409' }} />
    ) : quote.pickUpAtPharmacy ? (
        <MdLocalPharmacy style={{ fontSize: '20px', color: '#401409' }} />
    ) : null;

    const getEstimateExpressDateLabel = (estimateExpressDate) => {
        if (!estimateExpressDate) {
            return "No disponible";
        }
        try {
            const now = new Date();
            const estDate = new Date(estimateExpressDate);
            const isSameDay = now.toDateString() === estDate.toDateString();
            // Check if it's the next day
            const tomorrow = new Date(now);
            tomorrow.setDate(now.getDate() + 1);
            const isNextDay = estDate.toDateString() === tomorrow.toDateString();

            if (isSameDay) {
                const diffMs = estDate - now;
                const diffHours = diffMs / 3600000; // milliseconds in an hour
                const roundedHours = Math.round(diffHours);
                return (
                    <span><b>{`${roundedHours} h${roundedHours !== 1 ? 'rs' : ''}`}</b></span>
                );
            } else if (isNextDay) {
                const formattedTime = estDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
                return (
                    <span>Mañana <b>{`~${formattedTime}`}</b></span>
                );
            } else {
                const day = estDate.getDate().toString().padStart(2, '0');
                const month = (estDate.getMonth() + 1).toString().padStart(2, '0');
                return (
                    <span>Entrega <b>{`${day}/${month}`}</b></span>
                );
            }
        } catch {
            return "No disponible";
        }
    };

    return (
        <Card
            onClick={onClick}
            style={{
                borderRadius: '0.5em',
                height: '90px',
                width: '100%',
                boxSizing: 'border-box',
                margin: 0,
                marginBottom: '0.2em',
                padding: 0,
                overflow: 'hidden', // ensure a clean rounded corner look
                backgroundColor: '#f7f7f7',
                boxShadow: isSelected ? `0 0 0 0.2rem ${colorCode}` : 'none',
            }}
        >
            <Card.Header
                style={{
                    borderTop: `6px solid ${colorCode}`,
                    padding: 0,
                    borderBottom: 'none', // remove default header bottom border
                    backgroundColor: 'transparent'
                }}
            >
            </Card.Header>

            <Card.Body
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0em',
                    borderRadius: '0.5em',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <img
                        src={quote.pharmaceuticalGroupLogo?.url || ""}
                        alt=""
                        style={{
                            height: '35px',
                            marginRight: '0.3em',
                            borderRadius: '0.4em',
                            objectFit: 'cover',
                        }}
                    />                    
                    {quote.express && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'left',
                                margin: '0',
                                marginRight: '0.2em',                                
                                padding: '0',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '0.2em',
                                }}
                            >
                                {icon}
                                <span
                                    style={{
                                        fontSize: '0.6em',
                                        color: '#401409',
                                        marginLeft: '0.3em',
                                        marginRight: '0.3em',
                                    }}
                                >
                                    {quote.distanceStr}
                                </span>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    margin: '0',
                                }}
                            >
                                <MdAccessTimeFilled style={{ fontSize: '15px', color: '#401409' }} />
                                <span
                                    style={{
                                        fontSize: '0.56em',
                                        color: '#401409',
                                        marginLeft: '0.3em',
                                    }}
                                >
                                    {getEstimateExpressDateLabel(quote.estimateExpressDate)}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                {/* New row for watch icon & label */}
                
                <p
                    style={{
                        margin: '0',
                        marginTop: '0.2em',
                        color: '#401409',
                        fontSize: quote.pharmacyDescription.length>25 ? '0.46em' : '0.7em',
                        textWrap: quote.pharmacyDescription.length>25 ? 'wrap' : 'nowrap',                        
                        textAlign: 'center',                        
                    }}
                >
                    {quote.pharmacyDescription}                </p>                <p                    style={{                        margin: '0',                        fontWeight: 'bold',                        color: '#401409',                        fontSize: '0.8em',                        marginTop: quote.pharmacyDescription.length>25 ? '0.5em' : '0',                    }}                >
                    {formattedPrice}
                </p>
            </Card.Body>
        </Card>
    );
};

export default OrderQuote;
