import { CURRENT_BACKEND } from "../config/BackendConfiguration";

export class ShoppingCartService {
    constructor() {
        this.backendConfiguration = CURRENT_BACKEND;
    }

    addToCart(orderItems, authorization) {
        console.log(orderItems);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", authorization);
        myHeaders.append("Content-Type", "application/json");        
        var requestItems = [];
        orderItems.forEach(orderItem => {
            var payload = {
                "relatedEntityId": orderItem.relatedEntityId,
                "quantity": orderItem.quantity,
                "relatedEntityClass": orderItem.relatedEntityClass,
                "prescriptionId": orderItem.prescriptionId
            };
            requestItems.push(payload);
        });        
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            credentials: 'include',
            body: JSON.stringify(requestItems)
        };
        return fetch(this.backendConfiguration.baseUrl + "/cart/add", requestOptions)
            .then(response => response.json());
    }

    addPrescriptionToCart(privateKey, authorization) {
        var myHeaders = new Headers();
        myHeaders.append("Cookie", authorization);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            credentials: 'include'
        };

        return fetch(this.backendConfiguration.baseUrl + "/cart/add/prescription?prescriptionKey=" + privateKey, requestOptions)
            .then(response => response.json());
    }

    
    removeFromCart(itemId, authorization) {
        var myHeaders = new Headers();
        myHeaders.append("Cookie", authorization);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            credentials: 'include'
        };
        return fetch(this.backendConfiguration.baseUrl + "/cart/delete?id=" + itemId, requestOptions)
            .then(response => response.json());
    }

    updateCartItem(id, quantity, authorization) {
        var myHeaders = new Headers();
        myHeaders.append("Cookie", authorization);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            credentials: 'include'
        };
        return fetch(this.backendConfiguration.baseUrl + "/cart/update?id=" + id + "&quantity=" + quantity + "&itemType=com.drsmapp.entities.vademecum.Drug", requestOptions)
            .then(response => response.json());
    }

    updateCartByCache(items, authorization) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Cookie", authorization);
        var raw = JSON.stringify({
            "id": null,
            "lastModificationDate": null,
            "createDate": null,
            "subtotal": null,
            "discount": null,
            "tax": null,
            "total": null,
            "items": items
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
            credentials: 'include'
        };
        return fetch(this.backendConfiguration.baseUrl + "/cart/update/cache", requestOptions).then(response => response.json());
    }

    getCart(authorization) {
        var myHeaders = new Headers();
        myHeaders.append("Cookie", authorization);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
            credentials: 'include'
        };
        return fetch(this.backendConfiguration.baseUrl + "/cart?statusCodeFilter=1", requestOptions)
            .then(response => response.json());
    }

    getCartQuotes(latitude, longitude, authorization) {
        var myHeaders = new Headers();
        myHeaders.append("Cookie", authorization);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
            credentials: 'include'
        };
        return fetch(this.backendConfiguration.baseUrl + "/cart/quote?latitude=" + latitude + "&longitude=" + longitude, requestOptions)
            .then(response => response.json());
    }

    createOrderFromQuote(quoteId, authorization = null) {
        var myHeaders = new Headers();
        if(authorization){
            myHeaders.append("Cookie", authorization);
        }        
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            credentials: 'include'
        };
        return fetch(this.backendConfiguration.baseUrl + "/payment/order/cartquote?cartQuoteId=" + quoteId, requestOptions)
            .then(response => response.json());
    }

    getOrderHistory(fromDay, fromMonth, fromYear, toDay, toMonth, toYear, authorization) {
        var myHeaders = new Headers();
        myHeaders.append("Cookie", authorization);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
            credentials: 'include'
        };
        return fetch(this.backendConfiguration.baseUrl + "/orders/detail?fromDay=" + fromDay + "&fromMonth=" + fromMonth + "&fromYear=" + fromYear + "&toDay=" + toDay + "&toMonth=" + toMonth + "&toYear=" + toYear, requestOptions)
            .then(response => response.json());
    }

    // New method to quote a cached cart with current dates
    getQuoteForCachedCart(cachedCartItems, latitude, longitude) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");       
        var currentDate = new Date().toISOString();  // Current date in ISO format

        var raw = JSON.stringify({
            "cachedCart": {
                "id": "",
                "lastModificationDate": currentDate,
                "createDate": currentDate,
                "subtotal": null,
                "discount": null,
                "tax": null,
                "total": null,
                "items": cachedCartItems
            },
            "latitude": latitude,
            "longitude": longitude
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
            credentials: 'include'
        };

        return fetch(this.backendConfiguration.baseUrl + "/cart/quote/cached", requestOptions)
            .then(response => response.json());
    }
    
    addPrescriptionToCachedCart(privateKey, cachedCart) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(cachedCart),
            redirect: 'follow',
            credentials: 'include'
        };

        return fetch(this.backendConfiguration.baseUrl + "/cart/add/prescription/cache?prescriptionKey=" + privateKey, requestOptions)
            .then(response => response.json());
    }    
}
