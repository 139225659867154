import React from 'react';
import { Card } from 'react-bootstrap';
import { IoMdRefresh } from 'react-icons/io';

const RefreshQuote = ({ onClick }) => {
  const color = '#fdbf20'; // The yellow color used in the app
  return (
    <Card
      onClick={onClick}
      style={{
        borderRadius: '0.5em',
        height: '90px',
        width: '100%',
        boxSizing: 'border-box',
        margin: 0,
        marginBottom: '0.2em',
        padding: 0,
        overflow: 'hidden',
        backgroundColor: '#f7f7f7',
      }}
    >
      <Card.Header
        style={{
          borderTop: `6px solid ${color}`,
          padding: 0,
          borderBottom: 'none',
          backgroundColor: 'transparent',
        }}
      ></Card.Header>
      <Card.Body
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0.5em',
          borderRadius: '0.5em',
        }}
      >
        <IoMdRefresh style={{ fontSize: '2em', color: color }} />
        <p
          style={{
            margin: '0',
            color: '#401409',
            fontSize: '0.7em',
            textAlign: 'center',
            marginTop: '0.5em',
          }}
        >
          Refrescar cotizaciones
        </p>
      </Card.Body>
    </Card>
  );
};

export default RefreshQuote;
