// ...existing code (if any)...

import { createSlice, createAction } from '@reduxjs/toolkit';

const initialState = {
  addresses: [],
  paymentMethods: [],
  orderItems: [],
  selectedAddress: null,
  selectedPaymentMethod: null,
  guestUserData: {},
  addedPrescriptions: [],
};

export const tempSlice = createSlice({
  name: 'temp',
  initialState,
  reducers: {
    storeGuestData: (state, action) => {
      const { addresses, paymentMethods, orderItems, selectedAddress, selectedPaymentMethod, guestUserData, addedPrescriptions } = action.payload;
      state.addresses = addresses;
      state.paymentMethods = paymentMethods;
      state.orderItems = orderItems;
      state.selectedAddress = selectedAddress;
      state.selectedPaymentMethod = selectedPaymentMethod;
      state.guestUserData = guestUserData || {};
      state.addedPrescriptions = addedPrescriptions || [];
    },
    promoteGuestData: (state, action) => {
      // We'll rely on a thunk or external flow to merge this data into StoreSlice,
      // call the web services, and update local storage.
    },
    clearGuestData: (state) => {
      state.addresses = [];
      state.paymentMethods = [];
      state.orderItems = [];
      state.selectedAddress = null;
      state.selectedPaymentMethod = null;
      state.guestUserData = {};
      state.addedPrescriptions = [];
    },
  },
});

export const { storeGuestData, promoteGuestData, clearGuestData } = tempSlice.actions;
export default tempSlice.reducer;
